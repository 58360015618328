import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import DaumPost from '@components/DaumPost'

import * as Yup from 'yup';
import { Link, useParams } from 'react-router-dom';
import { getMyCloseMission, createGiveawayRequest } from '@actions/track';
import { setIsLoading } from '@actions/common';
import { user } from '@actions/account'
import config from '@/config';

const RewardRequest = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const currentUser = useSelector((state) => state.user);

  const [giveaways, setGiveaways] = useState(null);
  const [isRewardClaim, setIsRewardClaim] = useState(false);
  const [fillToUserInformation, setFillToUserInformation] = useState(false);
  const [isLocation, setIsLocation] = useState({
    address:'',
    zip:''
  });
  const [isPostPop, setIsPostPop] = useState(false);

  const validationSchema = Yup.object().shape({
    giveaway: Yup.string().nullable().required('경품을 선택해 주세요.'),
    name: Yup.string().required('받으실분 이름을 입력해 주세요.'),
    email: Yup.string().required('이메일을 입력해 주세요.').email('올바른 이메일을 입력해 주세요.'),
    address: Yup.string().required('주소를 입력해 주세요.'),
    address_detail: Yup.string(),
    mobile: Yup.string().required('휴대폰 번호를 입력해 주세요.'),
    message: Yup.string(),
  });

  const formOptions = {
    defaultValues: {
      giveaway: '',
      name: '',
      email: '',
      address: '',
      address_detail: '',
      mobile: '',
      message: '',
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  console.log(formData)

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getMyCloseMission(params.track_id))
    .then((res) => {
      dispatch(setIsLoading(false));

      if(res.result && !res.is_request_giveaway) {
        setGiveaways(res.giveaways)
      } else if(res.result && res.is_request_giveaway) {
        history.navigate(`/missions/${params.mission_id}/reward/${params.track_id}/view`, {replace: true})    
      } else {
        history.navigate(`/my-missions/all`, {replace: true})    
      }
    })
    .catch(message => {
      dispatch(setIsLoading(false));
      history.navigate(`/my-missions/all`, {replace: true})  
    })
  }, []);  

  useEffect(() => {
    if(isLocation.address) {
      setValue('address', isLocation.address, {shouldValidate: true});
    }
  }, [isLocation])

  useEffect(() => {
    if(fillToUserInformation) {
      dispatch(user(currentUser.user.id))
      .then((res) => {
        setValue('name', res.name, {shouldValidate: true});
        setValue('email', res.email, {shouldValidate: true});
        setValue('mobile', res.mobile, {shouldValidate: true});
        setValue('address', res.address, {shouldValidate: true});
        setValue('address_detail', res.address_detail, {shouldValidate: true});
      })
    } else {
      setValue('name', '', {shouldValidate: true});
      setValue('email', '', {shouldValidate: true});
      setValue('mobile', '', {shouldValidate: true});
      setValue('address', '', {shouldValidate: true});
      setValue('address_detail', '', {shouldValidate: true});
    }
  }, [fillToUserInformation])
  
  const handleComplete = (data) => {
      setIsPostPop(!isPostPop);
  }

  const onSubmit = (data) => {
    console.log(data)
    dispatch(createGiveawayRequest(params.track_id, data))
    .then((res) => {
      history.navigate(`/my-missions/all`, {replace: true})
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
      {giveaways && <div className="container">
        <form id="mission_rewards" onSubmit={handleSubmit(onSubmit)}>
          <div className="container_inner">
            <h2 className="mb20">경품 받기</h2>
            <p className="colblue mb15"><strong>경품 선택</strong></p>
            <fieldset className="choose_rewards_wrap">
              {giveaways.map(item=><label htmlFor={`choose_rewards_${item._id}`}>
                {item?.image?.server_filename ? <img src={`${config.apiServerHost}/giveaways/attachment/${item?.image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/282'}/> : <img src={`https://via.placeholder.com/282`}/>} 
                <strong>{item.title}</strong>
                <input type="radio" name="choose_rewards" id={`choose_rewards_${item._id}`} {...register("giveaway")} className="chk_circle" value={item._id}/>
              </label>)}
            </fieldset>
            {errors && errors?.giveaway?.message && <p className="input_warn mb30 text-center">
              <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.giveaway?.message}
            </p>}
          </div>
          <span className="divide_thick mb20"></span>
          <div className="container_inner container_fit">
              <div className="rewards_info_flex">
              <p className="colblue mb15"><strong>받는 사람</strong></p>
              <div>
                  <input type="checkbox" name="rewards_info_load" id="rewards_info_load" className="chk_default" checked={fillToUserInformation} onChange={()=>setFillToUserInformation(!fillToUserInformation)}/>
                  <label htmlFor="rewards_info_load"><small>내 정보와 동일</small></label>
              </div>
              </div>
              <fieldset className="rewards_receive_info">
              <label htmlFor="rewards_receiver">이름</label>
              <div className="input_clear">
                  <input type="text" id="rewards_receiver" className="input_default" placeholder="미션왕" {...register("name")} />
                  <button className="btn_clear" type="button"><span className="hide" onClick={()=>setValue('name', '')}>내용 지우기</span></button>
                  {errors && errors?.name?.message && <p className="input_warn">
                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.name?.message}
                  </p>}
              </div>
              <label htmlFor="rewards_email">이메일(아이디)</label>
              <div className="input_clear">
                  <input type="text" id="rewards_email" className="input_default" placeholder="mission@missionfriends.co.kr" {...register("email")} />
                  <button className="btn_clear" type="button"><span className="hide" onClick={()=>setValue('email', '')}>내용 지우기</span></button>
                  {errors && errors?.email?.message && <p className="input_warn">
                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.email?.message}
                  </p>}
              </div>
              <label htmlFor="rewards_phone">휴대폰 번호</label>
              <div className="input_clear">
                  <input type="text" id="rewards_phone" className="input_default" placeholder="010-1234-1234" {...register('mobile',{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} />
                  <button className="btn_clear" type="button"><span className="hide" onClick={()=>setValue('mobile', '')}>내용 지우기</span></button>
                  {errors && errors?.mobile?.message && <p className="input_warn">
                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.mobile?.message}
                  </p>}
              </div>
              <div className="input_address_wrap mb30">
                  <label htmlFor="acc_add1">주소</label>
                  <div className="input_postcode">
                      <div className="input_clear fit-width" >
                        <input type="text" id="acc_add2" className="input_default" value={formData?.address} {...register("address")} readOnly/>
                        {errors && errors?.address?.message && <p className="input_warn">
                          <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.address?.message}
                        </p>}
                      </div>
                      <button type='button' onClick={handleComplete}>검색</button>
                      {isPostPop && <DaumPost location={isLocation} setLocation={setIsLocation}></DaumPost>}
                  </div>
                  <input type="text" id="acc_add3" className="input_default" placeholder='상세주소를 입력해 주세요' {...register("address_detail")}/>
              </div>
              <label htmlFor="rewards_deliver_note">배송메세지</label>
              <select className="select_default" id="rewards_deliver_note" name="rewards_deliver_note" onChange={e=>setValue('message', e.target.value)}>
                  <option disabled hidden selected>배송메시지를 입력해 주세요</option>
                  <option value="부재 시 경비실에 맡겨주세요">부재 시 경비실에 맡겨주세요.</option>
                  <option value="부재 시 문 앞에 놓아주세요.">부재 시 문 앞에 놓아주세요.</option>
                  <option value="배송 전 연락 바랍니다.">배송 전 연락 바랍니다.</option>
              </select>
              <div className="input_clear">
                <input type="text" id="rewards_deliver_note_text" placeholder="요청사항을 직접 입력해 주세요" className="input_default" {...register("message")}/>
                <button className="btn_clear" type="button"><span className="hide" onClick={()=>setValue('message', '')}>내용 지우기</span></button>
              </div>
              </fieldset>
          </div>
          <div className="rewards_bottom">
              <button type="submit" className="btn_lg">신청하기</button>
          </div>
        </form>
      </div>}
      <div id="claim_rewards" className="modal fade" style={{display: isRewardClaim ? 'block' : 'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal-pop">
            <div className="modal-body">
            <div className="modal_text_center">
                <strong>경품 신청이 정상적으로 접수 되었습니다.<br/>마이페이지 > 나의 미션 페이지에서 경품 내용을 확인하실 수 있습니다.</strong>
            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_default" onClick={()=>setIsRewardClaim(false)}>확인</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RewardRequest;
