import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { login } from '@actions/account';

const LoginEmail = () => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    user_id: Yup.string().required('아이디를 입력해 주세요.').email('이메일을 입력해 주세요.'),
    password: Yup.string().required('비밀번호를 입력해 주세요.')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const fromData = watch();
  
  const onSubmit = (params) => {
    dispatch(login(params.user_id, params.password))
    .then((res) => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "token/save", data: res.accessToken})
        );
      }
      history.navigate(`/`, { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        <div className="login_back_wrap">
          <Link to="/login" className="btn_back"></Link>
          </div>
          <div className="container">
            <div className="container_inner">
              <h2 className="mb60">미션프렌즈에 오신 것을<br/>환영합니다 :)</h2>
              <form id="login_email" onSubmit={handleSubmit(onSubmit)}>
                <fieldset className="mb15">
                  <label htmlFor="email_id"><div className="hide">아이디(이메일)</div></label>
                  <div className="input_clear mb15">
                    <input type="text" className="input_default" name="email_id" {...register('user_id',{ onChange: (e)=>{ e.target.value = e.target.value.toLowerCase();}})} placeholder="이메일을 입력해 주세요" style={{imeMode:'inactive', textTransform: 'lowercase'}}/>
                    {fromData?.user_id?.length > 0 && <button className="btn_clear" type="button" onClick={()=>setValue('user_id', '')}><span className="hide">내용 지우기</span></button>}
                    {errors && errors?.user_id?.message && <p className="input_warn">
                      <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.user_id?.message}
                    </p>}
                  </div>
                  <label htmlFor="email_pw"><div className="hide">비밀번호</div></label>
                  <input type="password" className="input_default" name="email_pw" {...register("password")} placeholder="비밀번호를 입력해 주세요"/>
                  {errors && errors?.password?.message && <p className="input_warn">
                    <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.password?.message}
                  </p>}
                </fieldset>
                <button type='submit' className="btn_lg">로그인</button>
              </form>
              <div className="login_email_bottom">
                {/* <div>
                  <input type="checkbox" name="chk_keepin" id="chk_keepin" className="chk_default mr5"/>
                  <label htmlFor="chk_keepin">로그인 상태 유지</label>
                </div> */}
                <Link to="/login/find" className="undline">아이디/비밀번호찾기</Link>
              </div>
            </div>
          </div>
    </>
  );
}

export default LoginEmail;
