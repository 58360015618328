import React, { useState } from 'react';
import DaumPostcodeEmbed  from 'react-daum-postcode';

const DaumPost = (props) => {
  const DaumPostStyle = {
    position:'absolute',
    display:'block',
    top:0,
    left:0,
    width:'100%',
    height:'50vh',
    zIndex: 9,
  }
  
  const handleSelect = (data) => {
    let fullAddress = data.address;
    let zipcode = data.zonecode
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
   
    console.log(zipcode, fullAddress)

    props.setLocation({
      ...props.location,
      address:fullAddress,
      zip:zipcode,
    })
  };

  return (
    <>
      <DaumPostcodeEmbed onComplete={handleSelect} autoClose style={DaumPostStyle}/>
    </>
  );
}
export default DaumPost;