import { useNavigate, Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu'
import history from '@helpers/history';

function Header(props) {
  const navigate = useNavigate();

  const navStyles = {
    bmBurgerButton: {
      position: 'absolute',
      width: '21px',
      height: '19px',
      top:'35px',
      right: '15px',
    },
    bmBurgerBars: {
      width: '21px',
      height: '2px',
      background: '#000'
    },
    bmCrossButton: {
      position:'fixed',
      top:'-44px',
      right:'24px',
      height: '24px',
      width: '24px',
    },
    bmCross: {
      width: '24px',
      height: '2px',
      background:'#000'
    },
    bmMenuWrap: {
      position: 'fixed',
      maxWidth: '1000px',
      height: 'auto',
      top:'80px',
    },
    bmMenu: {
      background: '#00BF8E',
    },
    bmItemList: {
      padding: '15px',
    },
    bmItem: {
      width: '100%',
      height: '60px',
      lineHeight: '60px',
      padding: '0 15px',
      borderRadius: '10px',
      
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
      top:'80px',
      left:0
    }
  }
  const closeMenu = () => {
    this.setState({menuOpen: false})
  }
  return (
      <div className="header_wrap">
      <header id="header">
        <div className="header_left">
          {history.location.pathname.toLowerCase().startsWith('/home') || ['/rank','/my-missions','/missions/solo','/missions/team','/missions/stamp', '/stamps', '/spots','/my'].includes(history.location.pathname.toLowerCase()) ? <Link to={'/home/solo'} className={`logo`}>
            <img src={require('@assets/img/logo.svg').default} alt="로고"/><span className="hide">미션프렌즈</span>
          </Link>
          :
          <a onClick={()=> navigate(-1)} className={`btn_back`}>
            <span className="hide">뒤로 가기</span>
          </a>}
        </div>
        <div className="header_right">
          <Link to="/notices"><button className="btn_notify"><span className="hide">알림창</span></button></Link>
          <Menu styles={navStyles} width={'100%'} noTransition right>
            <Link to="/missions/solo" className='mb10' onClick={()=> closeMenu()}>
              <img src={require('@assets/img/nav_1.png')}/>
              <span></span>
              <strong>1인 미션 투어</strong>
            </Link>
            <Link to="/missions/team" className='mb10' onClick={()=> closeMenu()}>
              <img src={require('@assets/img/nav_2.png')}/>
              <span></span>
              <strong>단체 미션 투어</strong>
            </Link>
            <Link to="/missions/stamp" className='mb10' onClick={()=> closeMenu()}>
              <img src={require('@assets/img/nav_3.png')}/>
              <span></span>
              <strong>스탬프 투어</strong>
            </Link>
            <Link to="/spots" onClick={()=> closeMenu()}>
              <img src={require('@assets/img/nav_4.png')}/>
              <span></span>
              <strong>광주 관광지</strong>
            </Link>
          </Menu>
        </div>
      </header>
    </div>
  );
}

export default Header;
