import api from '@/services/api';
import queryString from 'query-string';

class BoardService {
  notices(params) {
    return api.get(`/boards/notice?${queryString.stringify(params)}`)
  }

  notice(notice_id) {
    return api.get(`/boards/notice/${notice_id}`)
  }

  events(params) {
    return api.get(`/boards/event?${queryString.stringify(params)}`)
  }

  event(event_id) {
    return api.get(`/boards/event/${event_id}`)
  }
}

export default new BoardService();
