import { useState} from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const Terms = () => {
  const [ selectTerms, setSelectTerms] = useState('young_terms');
  const common = useSelector((state) => state.common);

  return (
    <>
      <div className="container">
        <div className="container_inner"><h2>약관보기</h2></div>
        <div className="terms_tab_wrap">
            <label htmlFor="term_content[0]" className={selectTerms === 'young_terms' ? 'active' : ''}>만 14세 이상입니다</label>
            <label htmlFor="term_content[1]" className={selectTerms === 'use_terms' ? 'active' : ''}>서비스 이용 약관</label>
            <label htmlFor="term_content[2]" className={selectTerms === 'privacy_terms' ? 'active' : ''}>개인정보 처리방침</label>
            {/* <label htmlFor="term_content[3]">개인정보 제3자 제공</label> */}
        </div>
        <div className="container_inner">
          <ul className="term_container">
            <li>
              <input type="radio" name="term_contents" id="term_content[0]" value={'young_terms'} onChange={e=>setSelectTerms(e.target.value)} checked={selectTerms === 'young_terms'}/>
              <div className="term_content">
              <p dangerouslySetInnerHTML={{__html: common.site.young_terms}} />
              </div>
            </li>
            <li>
              <input type="radio" name="term_contents" id="term_content[1]" value={'use_terms'} onChange={e=>setSelectTerms(e.target.value)} checked={selectTerms === 'use_terms'}/>
              <div className="term_content">
              <p dangerouslySetInnerHTML={{__html: common.site.use_terms}} />
              </div>
            </li>
            <li>
              <input type="radio" name="term_contents" id="term_content[2]" value={'privacy_terms'} onChange={e=>setSelectTerms(e.target.value)} checked={selectTerms === 'privacy_terms'}/>
              <div className="term_content">
              <p dangerouslySetInnerHTML={{__html: common.site.privacy_terms}} />
              </div>
            </li>
            {/* <li>
              <input type="radio" name="term_contents" id="term_content[3]" />
              <div className="term_content">
              <p dangerouslySetInnerHTML={{__html: common.site.use_terms}} />
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Terms;


