import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import DaumPost from '@components/DaumPost'
import * as Yup from 'yup';

import { signup } from '@actions/account';

const RegisterForm = (props) => {
  const [isLocation, setIsLocation] = useState({
    address:'',
    zip:''
  });
  const [isPostPop, setIsPostPop] = useState(false);
  const handleInput = (e) => {
    // setIsLocation({
    //     ...isLocation,
    //       [e.target.name]:e.target.value,
    //   })
    setValue('address', e.target.value)
  }
  const handleComplete = (data) => {
    setIsPostPop(!isPostPop);
  }
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    nickname: Yup.string().required('닉네임을 입력해 주세요.'),
    user_id: Yup.string().required('아이디를 입력해 주세요.').email('이메일을 입력해 주세요.'),
    password: Yup.string()
              .required('비밀번호를 입력해 주세요.')
              .min(8, '비밀번호는 최소 8자리 이상으로 입력해 주세요.')
              .max(20, '비밀번호는 최대 20자리 이하로 입력해 주세요.'),
    confirmPassword: Yup.string()
              .required('비밀번호를 한번 더 입력해 주세요.')
              .oneOf([Yup.ref('password')], '입력하신 비밀번호가 다릅니다.'),
    address: Yup.string(),
    address_detail: Yup.string(),
    how: Yup.string(),
    agree_sms: Yup.boolean(),
    agree_email: Yup.boolean(),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();


  useEffect(() => {
    if(isLocation.address) {
      setValue('address', isLocation.address, {shouldValidate: true});
    }
  }, [isLocation])


  const onSubmit = (params) => {
    dispatch(signup(params))
    .then((res) => {
      history.navigate(`/register/done`, { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
      <div className="login_back_wrap">
        <Link to="/register/term" className="btn_back"><span className="hide">뒤로 가기</span></Link>
      </div>
      <div className="container">
          <div className="container_inner">
            <h2 className="mb40">회원가입</h2>
          </div>
          <form id="register_account" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="register_account_required container_inner">
              <label htmlFor="acc_nickname">닉네임</label>
              <div className="input_clear">
                <input type="text" id="acc_nickname" {...register("nickname")} className="input_default"/>
                {/* <button className="btn_clear" type="button"><span className="hide">내용 지우기</span></button> */}
                {errors?.nickname?.message && <p className="input_warn">
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.nickname?.message}
                </p>}
              </div>
              
              <label htmlFor="acc_email">이메일(아이디)</label>
              <div className="input_clear">
                <input type="text" id="acc_email" className="input_default" {...register('user_id',{ onChange: (e)=>{ e.target.value = e.target.value.toLowerCase();}})} placeholder="@까지 정확하게 입력해 주세요" style={{imeMode:'inactive', textTransform: 'lowercase'}}/>
                {/* <button className="btn_clear" type="button"><span className="hide">내용 지우기</span></button> */}
                {errors?.user_id?.message && <p className="input_warn">
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.user_id?.message}
                </p>}
              </div>
              <label htmlFor="acc_pw">비밀번호</label>
              <div className="input_clear">
                <input type="password" id="acc_pw" className="input_default" {...register("password")} placeholder="비밀번호(숫자+영문+특수문자 조합 8자 이상)"/>
                {/* <button className="btn_clear" type="button"><span className="hide">내용 지우기</span></button> */}
                {errors?.password?.message && <p className="input_warn">
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.password?.message}
                </p>}
              </div>
              <label htmlFor="acc_pw2">비밀번호 확인</label>
              <div className="input_clear">
                <input type="password" id="acc_pw2" className="input_default" {...register("confirmPassword")} placeholder="비밀번호를 한번 더 입력해 주세요"/>
                {/* <button className="btn_clear" type="button"><span className="hide">내용 지우기</span></button> */}
                {errors?.confirmPassword?.message && <p className="input_warn">
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.confirmPassword?.message}
                </p>}
              </div>
              {/* <label htmlFor="acc_phone">휴대폰 번호</label>
              <input type="text" id="acc_phone" className="input_default" disabled value="01012345678"/> */}
            </fieldset>
            <div className="register_account_optional_bg">
              <div className="container_inner">
                <p className="mb15"><strong>추가정보 (선택)</strong></p>
                <fieldset className="register_account_optional">
                  <label htmlFor="acc_email" style={{display:'block',marginBottom:'10px'}}>이름</label>
                  <div className="input_clear">
                    <input type="text" id="name" className="input_default" placeholder="이름" {...register("name")} />
                    <button className="btn_clear" type="button" onClick={()=>setValue('name', '')}><span className="hide">내용 지우기</span></button>
                  </div>
                  <div className="input_address_wrap mb30">
                    <label htmlFor="acc_add1">주소</label>
                    <div className="input_postcode">
                      <input type="text" id="acc_add2" className="input_default" {...register("address")} value={formData.address} placeholder='주소를 검색해 입력해 주세요.'  readOnly/>
                      <button type='button' onClick={handleComplete}>검색</button>
                      {isPostPop && <DaumPost location={isLocation} setLocation={setIsLocation}></DaumPost>}
                    </div>
                    <input type="text" id="acc_add3" className="input_default" {...register("address_detail")} placeholder='상세주소를 입력해 주세요'/>
                  </div>
                  <p className="mb15"><strong>본 서비스는 어떻게 알게 되셨나요?</strong></p>
                  <select name="acc_how" id="acc_how" {...register("how")} className="select_default">
                    <option value=''>선택해 주세요</option>
                    <option value="0">인터넷 뉴스,기사</option>
                    <option value="1">신문/잡지</option>
                    <option value="2">블로그, 페이스북, 인스타그램</option>
                    <option value="3">관련 기관을 통해</option>
                    <option value="4">홍보 책자 및 팸플릿</option>
                    <option value="5">지인소개</option>
                    <option value="6">기타</option>
                  </select>
                  <div className="double_wrap">
                    <div>
                      <input type="checkbox" name="acc_agree_sms" id="acc_agree_sms" {...register("agree_sms")} className="chk_default"/>
                      <label htmlFor="acc_agree_sms">문자 수신 여부</label>
                    </div>
                    <div>
                      <input type="checkbox" name="acc_agree_email" id="acc_agree_email" {...register("agree_email")} className="chk_default"/>
                      <label htmlFor="acc_agree_email">이메일 수신 여부</label>
                    </div>
                  </div>
                </fieldset>
                <small>* 문자 및 이메일 수신에 체크 시 경품 및 이벤트 상품을 안내받으실 수 있습니다.</small>
                <button type='submit' className="btn_lg">회원가입 완료</button>
              </div>
            </div>
          </form>
      </div>
    </>
  );
}

export default RegisterForm;
