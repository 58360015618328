import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import config from '@config';

const LoginHome = () => {
  const currentUser = useSelector(state => state.user);
  
  useEffect(() => {
    if(currentUser?.isLoggedIn){
      window.location.href = '/home/solo';
    }
  }, [currentUser?.isLoggedIn]);

  const kakaoLoginUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${config.kakaoAppKey}&redirect_uri=${encodeURI(config.kakaoCallbackUri)}&response_type=code`
  const naverLoginUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${config.naverClientId}&redirect_uri=${encodeURI(config.naverCallbackUri)}&state=${Math.random().toString(36).substr(3, 14)}`;
  
  return (
    <>
      <div className="login_full_container">
        <div className="container">
          <div className="login_top mb30">
            <h2>간편로그인</h2>
            <img src={require("@assets/img/logo_wh.svg").default} alt="미션프렌즈 로고 화이트"/>
          </div>
          <ul className="login_methods">
            <li className="login_method"><a href={kakaoLoginUrl}>카카오 로그인</a></li>
            <li className="login_method"><a href={naverLoginUrl}>네이버 로그인</a></li>
            {/* <li className="login_method"><a href="#">페이스북 로그인</a></li>
            <li className="login_method"><a href="#">애플 로그인</a></li> */}
            <li className="login_method"><Link to="/login/email">이메일 로그인</Link></li>
          </ul>
          <div className="login_bottom">
            <Link to='/register/term' className="undline">회원가입</Link>
            <small>계정 생성 시 이용약관, 개인정보처리방침<br/>및 위치기반 서비스 이용약관에 동의하게 됩니다.</small>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginHome;
