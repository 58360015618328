import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { mission } from '@actions/mission';
import { setIsLoading } from '@actions/common';
import { getTeams, joinTeam } from '@actions/track';

import config from '@/config';
import useInterval  from '@helpers/useInterval';
import TimeCountDown from '@components/TimeCountDown'
import LottieAnimation from '@components/LottieAnimation';

const TeamJoin = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [missionData, setMissionData] = useState(null);
  const [teamData, setTeamData] = useState(null);
  const [team_id, setTeamId] = useState(null);
  const [is_joined_team, setIsJoinedTeam] = useState(false);
  const [isTeamConfirm, setIsTeamConfirm] = useState(false);
  const { isLoading } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(mission(params.mission_id))
      .then((res) => {
        setMissionData(res);
        dispatch(getTeams(params.mission_id))
        .then((res) => {
          dispatch(setIsLoading(false));
          if(res.length === 0){
            history.navigate(`/missions/${params.mission_id}/view`, { replace: true});
          } else {
            setTeamData(res)
            setIsJoinedTeam(_.some(res, r=>r.is_member))
          }
        })
        .catch((err) => {
          dispatch(setIsLoading(false));
        })
      })
      .catch(message => {
        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
        dispatch(setIsLoading(false));
      })

  }, []);

  useInterval(() => {
    dispatch(getTeams(params.mission_id))
    .then(res => {
      if (res.length === 0) {
        history.navigate(`/missions/${params.mission_id}/view`, { replace: true});
      } else if(!isLoading) {
        setTeamData(res)
      
        setIsJoinedTeam(_.some(res, r=>r.is_member))

        if(_.find(res, r=>r._id === team_id)?.member_count === missionData.max_team_persion){
          setTeamId(null);
          setIsTeamConfirm(false);
        }
      }
    })
  }, 1000);

  const onJoinTeam = () => {
    dispatch(setIsLoading(true));
    setIsTeamConfirm(false);
    dispatch(joinTeam(params.mission_id, team_id))
    .then(res => {
      setTeamData(res)
      setTeamId(null);
      setIsJoinedTeam(true);
      dispatch(setIsLoading(false));
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
      dispatch(setIsLoading(false));
    })
  }
  return (
    <>
      {missionData && teamData && <div className="container">
        <div className="container_inner">
        <div className="mission_alert active mb20">
          {`★ 팀이 모집되지 않으면 미션은 자동 취소됩니다.`}
        </div>
          <p className="detail_cate"><img src={require('@assets/img/icon_h2_blue.png')} /><strong className="colblue">단체 미션 투어</strong></p>
          <h2 className="mission_team_notice">참가 팀을 결정해 주세요!</h2>
          <small className="mission_team_desc">총 {missionData.max_team}개 팀으로 구성되며 팀당 최대 {missionData.max_team_persion}인으로 시작합니다.</small>
          <div className="detail_countdown_wrap">
              <div className="detail_count_down_left">
              <img src={require('@assets/img/icon_time_wh.png')} />
              <small>남은 시간 </small>
              <strong><TimeCountDown targetDate={new Date(missionData.from_date)}/></strong>
              </div>
              {/* <small>진행시간&nbsp;&nbsp;{helper.secondsToTime(moment(item.close_date).diff(moment(item.play_date), 'seconds'))}</small> */}
          </div>
          <div className="team_start_wrap">
              <form id="team_start">
                  {teamData.map((item, i)=><div key={item._id}>
                    <div className="team_start_text">
                        <label htmlFor={`team_start_${i}`} data-num={i+1}>{`${i+1}팀`}</label>  
                        <small className="team_pax"><strong className="colgreen">{item.member_count}</strong> / {missionData.max_team_persion}</small>
                        {!is_joined_team && <input type="radio" name="team_id" id={`team_start_${i}`} className="chk_default" onChange={e=>setTeamId(e.target.value)} value={item._id} checked={team_id === item._id} disabled={item.member_count === missionData.max_team_persion}/>}
                    </div>
                    {item.member_count > 0 && <div className="team_party_wrap">
                      <ul>
                        {item.members.map(r=><li>
                          <div className="team_party__img">
                            {r?.avatar?.server_filename ? <img src={`${config.apiServerHost}/accounts/attachment/${r?.avatar?.server_filename}`} alt="" width={'100%'} onError={e=> {e.target.onerror = null; e.target.src = 'https://via.placeholder.com/480x200'}}/> : <img src={require('@assets/img/avatar.png')} alt="" width={'100%'} />}
                          </div>
                          <p>{r.nickname} <span className="coldg">(참여 {r.success_mission_count + r.fail_mission_count} / 성공 {r.success_mission_count})</span></p>
                        </li>)}
                      </ul>
                    </div>}
                    {item.member_count === 0 && <label htmlFor={`team_start_${i}`}><div className="team_party_wrap">
                      <div className='no_data'>
                          <LottieAnimation id={`lottie_container${i}`} width="250px" data={require('@/assets/lottie/29712-insight-team.json')} display='inline-block'/>
                          {!is_joined_team && <div>
                            {`${i+1}팀의 첫번째 팀원이 되어 보세요!`}
                          </div>}
                        </div>
                    </div></label>}
                  </div>)}
              </form>
          </div>
        </div>
        <div className="mission_bottom start_bottom">
          {is_joined_team && <button type='button' className={`btn_lg mission_enter disabled`} disabled={true}>팀 선택 완료</button>}

          {!is_joined_team && !team_id && <button type='button' className={`btn_lg mission_enter disabled`}>팀을 선택해 주세요.</button>}

          {!is_joined_team && team_id && <button type='button' className={`btn_lg mission_enter`} onClick={()=>{
            if(!team_id) {
              toast.error('팀을 선택해 주세요.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 1000,
                hideProgressBar: true
              });
            } else {
              setIsTeamConfirm(true)
            }
          }}>{teamData?.find(t=>t._id === team_id)?.team_name} 선택</button>}
        </div>
      </div>}

      <div id="mission_done" className="modal fade" style={{display: isTeamConfirm ? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <strong>팀원이 되시겠습니까?<br/><small>선택 후 참가팀 변경을 하실 수 없습니다.</small></strong>                                
              </div>
            </div>
            <div className="modal-footer" style={{paddingTop:0}}>
              <button type="button" className="btn_pop_yes" onClick={()=>onJoinTeam()}>{teamData?.find(t=>t._id === team_id)?.team_name} 선택</button>
              <button type="button" className="btn_pop_no"  onClick={()=>setIsTeamConfirm(false)}>다시 선택</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamJoin;
