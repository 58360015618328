import history from '@helpers/history';
import { useSelector } from 'react-redux';

function Footer() {
  const common = useSelector((state) => state.common);

  return (
    <>
      <footer id="footer">
        <div className="mb15 colblue">
          <a href="#">서비스 이용약관</a>
          <span className="bar"></span>
          <a href="#">개인정보 처리방침 및 수집 이용동의</a>
        </div>
        <small>
          상호: (주)미션프렌즈<br/>
          주소: {common.site.address}<br/>
          대표: {common.site.owner_name} &nbsp;&nbsp; 사업자등록번호:123-1234-1234<br/>
          개인정보 보호책임자: {common.site.owner_name}
        </small>
      </footer>
    </>
  )
}

export default Footer;
