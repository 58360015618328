import { useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom'; 

import Moment from 'react-moment';
import config from '@config';

import { like } from '@actions/mission';

const TeamMission = ({data}) => {
  const dispatch = useDispatch();
  
  const [itemData, setItemData] = useState(data);

  const onLike = () => {
    dispatch(like(itemData._id))
    .then(res => {
      setItemData({...itemData, is_liked: res.result})
    })
  }
  return (
    
  <div className="mission_item">
    <Link to={`/missions/${itemData._id}/view`} className="img_wrap">
      <img src={`${config.apiServerHost}/missions/attachment/${itemData?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>{e.target.onerror = null; e.target.src = 'https://via.placeholder.com/480x200'}}/>
    </Link>
      <div className="mission_period_wrap">
        <small>
          <strong className="colblue">미션 시작일</strong>
          <Moment format='YYYY.MM.DD HH:mm'>{itemData.from_date}</Moment>
        </small>
        <button type="button" className={`btn_like ${itemData.is_liked ? "btn_liked" : ""}`} onClick={()=>onLike()}></button>
      </div>
      <h4 className="mission_title"><Link to={`/missions/${itemData.id}/view`}>{itemData.title}</Link></h4>
      <div className="mission_info">
        <small>
          <img src={require('@assets/img/icon_time_gray.png')}/><strong>{itemData.limit_time}시간 {itemData.max_team}팀 / {itemData.max_team_persion}인</strong>
        </small>
      </div>
    </div>
  );
}

export default TeamMission;
