import api from '@/services/api';

class SiteService {
  site() {
    return api.get(`/site`)
  }
  
  updateSite(params) {
    return api.put(`/site`, params)
  }

  updateTerms(params) {
    return api.put(`/site/terms`, params)
  }
}

export default new SiteService();
