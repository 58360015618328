import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { spots } from '@actions/spot';
import { setIsLoading } from '@actions/common';
import SpotItem from '@screens/Spot/Components/ListItem/Spot'
import LottieAnimation from '@components/LottieAnimation';

const SpotList = () => {
  const dispatch = useDispatch();
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(spots({
      page: currentPage,
      limit: pageLimit,
    }))
    .then((res) => {
      setTotalPageCount(1);
      setTotalItemCount(res.data.length);
      setCurrentItems(res.data);
      dispatch(setIsLoading(false));
    })
  }, []);

  return (
    <>
      <div className="container">
        <div className="container_inner container_fit">
          {/* <h2>관광지</h2>
          <div className="index_mission_wrap" style={{marginTop: '15px', borderTop:'1px solid #E1E3E7'}}> */}
          <div className="index_mission_wrap">
            <div className="mission_items">
              {
                currentItems && currentItems.length === 0 &&<div className='no_data'>
                <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                <span>관광지 정보가 없습니다.</span>
              </div>
              }
              {currentItems && currentItems.map((item, index) => 
                <SpotItem data={item}/>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpotList;
