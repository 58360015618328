import api from '@/services/api';
import queryString from 'query-string';

class LikeService {
  likes(params) {
    return api.get(`/likes?${queryString.stringify(params)}`)
  }
}

export default new LikeService();
