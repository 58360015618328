import { SET_IS_LOADING, SET_SITE_INFO, SET_IS_DENIED_GEOLOCATION } from '@actions/types';

const initialState = {
  isLoading: false,
  isDeniedGeolocation: false,
  site: {},
};

export default function common(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_LOADING:
      return { 
        ...state,
        isLoading: payload
      }
    case SET_IS_DENIED_GEOLOCATION:
      return { 
        ...state,
        isDeniedGeolocation: payload
      }
    case SET_SITE_INFO:
      return {
        ...state,
        site: payload
      }
    default:
      return state;
  }
}
