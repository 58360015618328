import Header from '@components/Header';
import Tab from '@components/Tab';
import { Outlet } from 'react-router-dom';
import history from '@/helpers/history';

export default function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      {!history.location.pathname.toLowerCase().startsWith('/missions') && <Tab/>}
    </>
  );
}
