import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import { user, logout, updateAvatar } from '@actions/account';
import { setIsLoading } from '@actions/common';

import config from '@config';

const MyHome = () => {

  const currentUser = useSelector(state => state.user);
  const [myData, setMyData] = useState();

  const onImageChange = (e) => {
    const [file] = e.target.files;
    const formData = new FormData();
    formData.append('image', file);
    dispatch(updateAvatar(currentUser.user.id, formData))
    .then((res) => {
      setMyData({
        ...myData,
        avatar: res
      })
      toast.success('프로필 이미지가 변경되었습니다.');
    })
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(user(currentUser.user.id))
    .then((res) => {
      dispatch(setIsLoading(false));
      setMyData(res);
    })
    .catch((err) => {
      toast.error(err.message);
      dispatch(setIsLoading(false));
    })
  }, [dispatch]);

  return (
    <>
      {myData && <div className="container container_fit">
        <div className="container_inner">
          <div className="my_profile_wrap">
            <div className="my_profile_img_wrap">
              <form>
                <div className="my_profile__img">
                  {/* <img src={myImg} alt="내 프로필 이미지" /> */}
                  {/* <img src={require('@assets/img/avatar.png')} alt="내 프로필 이미지" /> */}
                  {myData?.avatar?.server_filename ? <img src={`${config.apiServerHost}/accounts/avatar/${myData?.avatar?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = ''}/> : <img src={require('@assets/img/avatar.png')} alt="" width={'100%'} />}
                </div>
                <div className="my_profile__attach">
                  <label htmlFor="profile_attach"><span className="hide">프로필 이미지 첨부</span></label>
                  <input type="file" name="profile_attach" id="profile_attach" onChange={onImageChange} accept="image/*" />
                </div>
              </form>
            </div>
            <div className="my_profile_info_wrap">
              <h2 className="mb10"><span className="colgreen">{currentUser.user.nickname}</span>님, 안녕하세요!</h2>
              <div className="my_profile_info_links">
                <a href="#" className="undline" onClick={()=>dispatch(logout())}>로그아웃</a>
                <Link to="/my/settings" className='btn_default_r5_line' data-line="graysm">정보수정</Link>
              </div>
            </div>
          </div>
          <div className="my_profile_result_wrap triple_wrap">
            <div>참여 {myData.total_join_mission_count}</div>
            <div>성공 {myData.success_mission_count}</div>
            <div>실패 {myData.fail_mission_count}</div>
          </div>
        </div>
        <div className="my_profile_lists">
          <div className="double_wrap">
            <div>
              <Link to="/my-missions/all">
                <img src={require('@assets/img/icon_my1.png')} />
                <strong>나의 미션</strong>
              </Link>
            </div>
            <div>
              <Link to="/my/wish/all">
                <img src={require('@assets/img/icon_my3.png')} />
                <strong>찜 목록</strong>
              </Link>
            </div>
          </div>
        </div>
        <ul className="my_page_menus">
          <li className="my_page_menu">
            <Link to="/notices">공지사항</Link>
          </li>
          <li className="my_page_menu">
            <Link to="/events">이벤트</Link>
          </li>
          <li className="my_page_menu">
            <Link to="/terms">약관보기</Link>
          </li>
        </ul>
      </div>}
    </>
  );
}

export default MyHome;
