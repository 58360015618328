import api from '@/services/api';

class TrackService {
  createPlayMission(mission_id) {
    return api.post(`/tracks/${mission_id}/play`)
  }

  getMyPlayMission(track_id) {
    return api.get(`/tracks/${track_id}/play`)
  }

  createQuizAnswer(track_id, quiz_id, params) {    
    return api.post(`/tracks/${track_id}/play/${quiz_id}`, params)
  }

  myMission() {    
    return api.get(`/tracks/my`)
  }

  getMyCloseMission(track_id) {    
    return api.get(`/tracks/${track_id}/close`)
  }

  getTeams(mission_id) {
    return api.get(`/tracks/${mission_id}/teams`)
  }

  joinTeam(mission_id, team_id) {
    return api.post(`/tracks/${mission_id}/teams/${team_id}/join`)
  }

  createGiveawayRequest(track_id, params) {
    return api.post(`/tracks/${track_id}/giveaway-request`, params)
  }
}

export default new TrackService();
