import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';

import LottieAnimation from '@components/LottieAnimation';
import TimeCountDown from '@components/TimeCountDown'
import useWatchLocation from '@helpers/useWatchLocation'
import { getMyPlayMission, createQuizAnswer } from '@actions/track';
import * as helper from '@/helper'
import useInterval  from '@helpers/useInterval';
import {Map, MapMarker} from 'react-kakao-maps-sdk';
import useDynamicRefs from 'use-dynamic-refs';
import { setIsLoading } from '@actions/common';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const MissionStampPlay = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [getRef, setRef] =  useDynamicRefs();
  const [isMissionDone, setIsMissionDone] = useState(false);
  const [missionData, setMissionData] = useState(null);
  const [currentStamp, setCurrentStamp] = useState(null);
  const [tryCount, setTryCount] = useState(0);
  const [inCurrentStamp, setInCurrentStamp] = useState(false);

  const options = {
    enableHighAccuracy: true,
    timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
    // maximumAge: 1000 * 3600 * 24, // 24 hour
    // timeout: 5000,
    maximumAge: 0, // 24 hour
  }
  const { location, cancelLocationWatch, error } = useWatchLocation(options);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getMyPlayMission(params.track_id))
    .then((res) => {
      setMissionData(res)
      dispatch(setIsLoading(false));
    })
    .catch(message => {
      history.navigate(`/missions/${params.mission_id}/view`, {replace: true})  
    })
  }, []);

  useEffect(() => {
    console.log(missionData)
    if(missionData?.answer?.length && missionData?.mission?.quiz?.length)
    {
      if(missionData?.answer?.length === missionData?.mission?.quiz?.length && !isMissionDone){
        setIsMissionDone(true);
      }
    }
  }, [missionData?.answer?.length]);

  useEffect(() => {
    if(error == 'User denied Geolocation'){
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: true})
    } else {
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: false})
    }

    return () => {
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: false})
      cancelLocationWatch();
    }
  }, [location, error])

  useEffect(() => {
    if(!inCurrentStamp && missionData?.status === 'play' && currentStamp?.coordinate?.lat && currentStamp?.coordinate?.lng && location?.latitude && location?.longitude){
      if(helper.getDistanceFromLatLonInKm(currentStamp.coordinate.lat, currentStamp.coordinate.lng, location.latitude, location.longitude) < 0.1){
        if(navigator.vibrate){
          navigator.vibrate([100, 50, 100, 50, 100]);
        }
       
        setInCurrentStamp(true)

        dispatch(createQuizAnswer(params.track_id, currentStamp.id, {
          answer: {
            lat: location.latitude,
            lng: location.longitude
          },
          try_count: tryCount
        }))
        .then(res => {
          setMissionData(res)
          setCurrentStamp(null);
          setInCurrentStamp(false)
        })
        .catch(message => {
          setInCurrentStamp(false)
          toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        })
      }
    }
  }, [location]);

  useInterval(() => {
    dispatch(getMyPlayMission(params.track_id))
    .then((res) => {
      setMissionData({...res})
      if(currentStamp && !_.find(res.mission.quiz, {id: currentStamp.id})){
        setCurrentStamp(null)
        setInCurrentStamp(false)
      }
    })
  }, 1000);

  const scrollTo = (id) => {
    setTimeout(()=>{
      getRef(id).current.scrollIntoView({ behavior: 'smooth' })
    })
  }

  return (
    <>
      {missionData && <div className="container">
        <div className="mission_lists_countdown_wrap">
          <div className="detail_countdown_wrap">
            <div className="detail_count_down_left">
              <img src={require('@assets/img/icon_time_wh.png')} />
              <small>남은 시간 </small>
              &nbsp;<strong><TimeCountDown targetDate={new Date(missionData.end_date)}/></strong>
            </div>
            {/* <div className="detail_countdown_right">
              <small>AB457-DG542-GH333</small>
            </div> */}
          </div>
          <div className="mission_progress_wrap">
            <div className="mission_progress_bar">
              <span className="mission_progress_fill" style={{width: `${missionData.answer.length/missionData?.mission.quiz?.length * 100}%`}}></span>
            </div>
            <small><strong className="colgreen">{missionData.answer.length}</strong> / {missionData?.mission.quiz?.length}&nbsp;</small>
          </div>
        </div>
        <div className="container_inner container_fit">
            {/* 스탬프투어일 경우에만 노출 */}
            <div className={`mission_stamp_wrap ${missionData?.mission.stamp_image_divide_num === 4 ? 'grid-col-2':'grid-col-3'}`}>
              {
                _.filter(missionData?.mission.quiz, r=>r.title).map(item=><div key={item.id} className={`${missionData?.mission.stamp_image_divide_num === 4 ? `mission_stamp_quarter ${_.some(missionData.answer, {quiz: {id: item.id}}) ? 'active':''}`:`mission_stamp_nine ${_.some(missionData.answer, {quiz: {id: item.id}}) ? 'active':''}`} bg${missionData?.mission.stamp_image_num}`}><strong>{item.location.name}</strong></div>)
              }
            </div>
            {/* 스탬프투어일 경우에만 노출 */}
            <div className="mission_contents mission_list_contents ">
              <button className="btn_mission_back" onClick={()=>history.navigate(`/missions/${params.mission_id}/view`)} style={{zIndex: '1000'}}></button>
              {
                _.filter(missionData?.mission.quiz, r=>r.title).map((item, idx)=><div className={`mission_content ${_.some(missionData.answer, {quiz: {id: item.id}}) ? 'success' : ''}`} key={item.id} ref={setRef(item.id)}>
                {_.some(missionData.answer, {quiz: {id: item.id}}) && <img className="mission_success_mark" src={require('@assets/img/img_success.png')} />}
                <div className="mission_content__title">
                  <span data-num={idx+1}>M</span>
                  <h3>{item.location.name}</h3>
                </div>
                <p dangerouslySetInnerHTML={{__html: item.description}} />
                  {currentStamp?.id === item.id && <div className='mb10'>
                  {item.coordinate.lat && item.coordinate.lng && <Map
                    center={{
                      lat: item.coordinate.lat,
                      lng: item.coordinate.lng,
                    }}
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                    level={3}
                  >
                  <MapMarker
                    position={{
                      lat: item.coordinate?.lat,
                      lng: item.coordinate?.lng,
                    }}
                  />
                  </Map>}
                  <div style={{textAlign:'center'}}>
                    <LottieAnimation width="300px" data={require('@/assets/lottie/79913-walk-man.json')}/>
                    <span style={{color: 'blue', fontWeight: 'bold'}}>"{item.address}"</span>&nbsp;주소로 이동하여 미션을 수행하세요.
                  </div>
                </div>}
                <div className="mission_content_btn_wrap">
                  <button className="btn_default_r5" onClick={()=>{
                    setCurrentStamp(item);
                    scrollTo(item.id)
                  }} data-bg={`${currentStamp?.id === item.id || _.some(missionData.answer, {quiz: {id: item.id}}) ? 'gray' : ''}`} disabled={currentStamp?.id === item.id || _.some(missionData.answer, {quiz: {id: item.id}}) ? true : false}>{_.some(missionData.answer, {quiz: {id: item.id}}) ? '미션 완료' : '미션 시작'}</button>
                </div>
              </div>)}
            </div>
        </div>
      </div>}
      <div id="mission_done" className="modal fade" style={{display: isMissionDone? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <h2 className="mb10 colred">미션 성공</h2> 
                <strong>축하드립니다.<br/>미션을 모두 완료하셨습니다.<br/>경품을 신청해 주세요!</strong>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_pop_yes" onClick={()=>history.navigate(`/`, {replace: true})  }>홈으로 이동</button>
              <button type="button" className="btn_pop_no"  onClick={()=>history.navigate(`/missions/${params.mission_id}/reward/${params.track_id}/request`, {replace: true})  }>경품 받기</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionStampPlay;
