import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import * as Yup from 'yup';

import { me, updateAccount } from '@actions/account';

const LoginFindPassword = () => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    // password: Yup.string()
    //           .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
              .oneOf([Yup.ref('password')], 'Passwords must match'),
    firstName: Yup.string().required('Required field'),
    lastName: Yup.string().required('Required field'),
    company: Yup.string().required('Required field'),
    division: Yup.string().required('Required field'),
    region: Yup.string().required('Required field'),
    organization: Yup.string().required('Required field'),
    countryCode: Yup.string().required('Required field'),
    mobile: Yup.string().required('Required field')
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const countryCode = watch('countryCode');
  const mobile = watch('mobile');
  const isCheckMobile = watch('isCheckMobile');
  const [myPageData, setMyPageData] = useState(null);
  const [isDupMobile, setIsDupMobile] = useState(false);

  const company = watch('company');
  const region = watch('region');
  const organization = watch('organization');

  const onSubmit = (params) => {
    if(params.password){//패스워드 입력한 경우
      if(params.password.length < 6){
        setError('password', { message: 'Password must be at least 6 characters' });
        return;
      } else if (!params.confirmPassword) {
        setError('confirmPassword', { message: 'Required field' });
        return;
      } else if (params.password !== params.confirmPassword) {
        setError('confirmPassword', { message: 'Passwords must match' });
        return;
      }
    }

    if(!isCheckMobile && (myPageData.countryCode !== params.countryCode || myPageData.mobile !== params.mobile)){//휴대폰 번호입력이 된겨우
      setError('isCheckMobile', { message: 'Please check mobile' });
      return;
    }

    dispatch(updateAccount(myPageData.id, {
      firstName: params.firstName,
      lastName: params.lastName,
      password: params.password,
      confirmPassword: params.confirmPassword,
      company: params.company,
      division: params.division,
      region: params.region,
      organization: params.organization,
      countryCode: params.countryCode,
      mobile: params.mobile,
    }))
    .then((res) => {
      history.navigate(`/`, { replace: true })
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
     <div className="login_back_wrap">
        <a href="./login_email.php" className="btn_back"><span className="hide">뒤로 가기</span></a>
      </div>
      <div className="container" style={{paddingTop:0}}>
        <div className="login_find_tab">
          <Link to="/login/find">아이디 찾기</Link>
          <Link to="/login/findpw" className="on">비밀번호 찾기</Link>
        </div>
        <div className="container_inner">
          <div className="login_find_id mb20">
            <h2>가입하셨던 <span className="colblue">아이디</span>와 <span className="colblue">휴대폰 번호</span>를 입력해 주세요.</h2>
            <form id="find_id" className="mb20">
              <fieldset>
                <div className="input_clear mb15">
                  <input type="text" className="input_default" placeholder="가입했던 이메일 주소를 입력해 주세요"/>
                  <button className="btn_clear" type="button"><span className="hide">내용 지우기</span></button>
                </div>
                <div className="mb15">
                  <input type="text" className="input_default" placeholder="-를 제외한 번호를 입력해 주세요"/>
                  <button className="btn_default">인증번호</button>
                </div>
                <div className="input_countdown">
                  <input type="text" className="input_default"/>
                  <span className="code_countdown">03:00</span>
                  <button className="btn_default" data-bg="gray">인증완료</button>
                </div>
              </fieldset>
            </form>
            <button id="resend_code" className="mb20"><small>인증번호 다시 받기 </small><img src={require('@assets/img/arrow_right_gray_thin.png')} /></button>
            <button className="btn_blue"><Link to="/login/email">이메일 로그인</Link></button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginFindPassword;
