import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithKakao } from '@actions/account';
import { toast } from 'react-toastify';
import history from '@helpers/history';

//function kakao login callback
function KakaoLoginCallback() {
  const dispatch = useDispatch();

  const getCode = () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    return params.get('code');
  }

  const onKakaoLogin = async (code) => {
    dispatch(loginWithKakao(code))
    .then(res => {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "token/save", data: res.accessToken})
        );
      }
      history.navigate('/home/solo', { replace: true });
    })
    .catch(message => {
      toast.error(message);
      history.navigate('/', { replace: true });
    })
  }

  useEffect(() => {
    const code = getCode();
    if (code) {
      onKakaoLogin(code);
    } else {
      history.navigate('/', { replace: true });
    }
  }, []);
}

export default KakaoLoginCallback;
