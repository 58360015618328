import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';

import { likes } from '@actions/like';
import { setIsLoading } from '@actions/common';
import LottieAnimation from '@components/LottieAnimation';
import { Link, useParams } from 'react-router-dom';

import SoloMission from '@screens/Mission/Components/ListItem/SoloMission'
import TeamMission from '@screens/Mission/Components/ListItem/TeamMission'
import StampMission from '@screens/Mission/Components/ListItem/StampMission'
import SpotItem from '@screens/Spot/Components/ListItem/Spot'

const MyWish = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [filteredMissionData, setFilteredMissionData] = useState(null);

  useEffect(() => {
    if(!['all', 'solo', 'team', 'stamp', 'spot'].includes(params.type)){
      history.navigate('/my/wish/all', { replace: true });
    } else {
      setFilteredMissionData(null);
      dispatch(setIsLoading(true));
      dispatch(likes({type: params.type === 'all' ? '' : params.type }))
        .then((res) => {
          setFilteredMissionData(res);
          dispatch(setIsLoading(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setIsLoading(false));
        })
    }

  }, [params.type]);
  
  return (
    <>
        <div className="container">
          <div className="container_inner">
            <h2>찜</h2>
          </div>
          {/* <div className="search_cate_wrap mb20">
            <a href="#" className="active">전체</a>
            <a href="#">1인 미션 투어</a>
            <a href="#">단체 미션 투어</a>
            <a href="#">스탬프 투어</a>
            <a href="#">광주 관광지</a>
          </div> */}
          <div className="search_cate_wrap">
            <Link to={'/my/wish/all'} className={params.type === 'all' ? 'active' : ''}>전체</Link>
            <Link to={'/my/wish/solo'} className={params.type === 'solo' ? 'active' : ''}>1인 미션 투어</Link>
            <Link to={'/my/wish/team'} className={params.type === 'team' ? 'active' : ''}>단체 미션 투어</Link>
            <Link to={'/my/wish/stamp'} className={params.type === 'stamp' ? 'active' : ''}>스탬프 투어</Link>
            <Link to={'/my/wish/spot'} className={params.type === 'spot' ? 'active' : ''}>광주 관광지</Link>
          </div>
          <div className="container_inner container_fit">
            <div className="index_mission_wrap">
              <div className="mission_items" style={{marginTop: '13px'}}>
                {filteredMissionData && filteredMissionData.length > 0 ? filteredMissionData.map((item, index) => {
                  if(item.type === 'solo'){
                    return <SoloMission key={index} data={item.mission} />
                  } else if(item.type === 'team'){
                    return <TeamMission key={index} data={item.mission} />
                  } else if(item.type === 'stamp'){
                    return <StampMission key={index} data={item.mission} />
                  } else if(item.type === 'spot'){
                    return <SpotItem key={index} data={item.spot} />
                  }
                }) : <div className="no_data">
                  <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                  찜한 미션이 없습니다.
                </div>}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default MyWish;
