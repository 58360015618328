import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '@helpers/history';
import _ from 'lodash';

import { useParams } from 'react-router-dom';
import { getMyCloseMission } from '@actions/track';
import { setIsLoading } from '@actions/common';
import config from '@/config';

const RewardRequestView = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [requestGiveawayData, setRequestGiveawayData] = useState(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getMyCloseMission(params.track_id))
    .then((res) => {
      dispatch(setIsLoading(false));

      if(res.result && !res.is_request_giveaway) {
        history.navigate(`/missions/${params.mission_id}/reward/${params.track_id}/view`, {replace: true})   
      } else if(res.result && res.is_request_giveaway) {
        setRequestGiveawayData(res.giveaway_request_data)
      } else {
        history.navigate(`/my-missions/all`, {replace: true})    
      }
    })
    .catch(message => {
      dispatch(setIsLoading(false));
      history.navigate(`/my-missions/all`, {replace: true})  
    })
  }, []);  

  return (
    <>
      {requestGiveawayData && <div className="container">
        <div className="container_inner">
          <h2 className="mb20">경품 내역</h2>
          <p className="colblue mb15"><strong>경품 선택</strong></p>
          <div className="detail_mission_reward my_mission_reward">
            {/* <img src={require('@assets/img/img_dummy_g3.png')} alt="" /> */}
            <img src={`${config.apiServerHost}/giveaways/attachment/${requestGiveawayData?.giveaway?.image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/282'}/>
            <strong>{requestGiveawayData.giveaway.title}</strong>
          </div>
        </div>
        <span className="divide_thick mb20"></span>
        <div className="container_inner container_fit">
          <div className="rewards_info_flex">
            <p className="colblue mb15"><strong>받는 사람</strong></p>
          </div>
          <div className="my_rewards_info_wrap">
            <div className="my_rewards_info">
              <strong>이름</strong>
              <p>{requestGiveawayData.name}</p>
            </div>
            <div className="my_rewards_info">
              <strong>이메일(아이디)</strong>
              <p>{requestGiveawayData.email}</p>
            </div>
            <div className="my_rewards_info">
              <strong>휴대폰 번호</strong>
              <p>{requestGiveawayData.mobile}</p>
            </div>
            <div className="my_rewards_info">
              <strong>주소</strong>
              <p>{requestGiveawayData.address} {requestGiveawayData.address_detail}</p>
            </div>
            <div className="my_rewards_info">
              <strong>배송메세지</strong>
              <p>{requestGiveawayData.message || '-'}</p>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default RewardRequestView;
