import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Layout from '@screens/Layout';
import Home from '@screens/Home';

import NotFound from '@screens/NotFound';

import PrivateRoute from '@components/PrivateRoute'

import LoginHome from '@screens/Login/Home';
import LoginEmail from '@screens/Login/Email';
import LoginFindId from '@screens/Login/Find/Id';
import LoginFindPassword from '@screens/Login/Find/Password';

import RegisterTerm from '@screens/Register/Term';
import RegisterForm from '@screens/Register/Form';
import RegisterDone from '@screens/Register/Done';

import MissionList from '@screens/Mission/List';
import MissionDetail from '@screens/Mission/Detail';
import MissionSoloPlay from '@screens/Mission/SoloPlay';
import MissionTeamPlay from '@screens/Mission/TeamPlay';
import MissionStampPlay from '@screens/Mission/StampPlay';
import TeamJoin from '@screens/Mission/TeamJoin';

import MissionRewardRequest from '@screens/Reward/RewardRequest';
import MissionRewardRequestView from '@screens/Reward/RewardRequestView';

import Rank from '@screens/Rank/List';
import RankUserDetail from '@screens/Rank/User';

import MyMissionList from '@screens/MyMission/List';
import MyMissionHistory from '@screens/MyMission/History';

import SpotList from '@screens/Spot/List';
import SpotDetail from '@screens/Spot/Detail';

import MyHome from '@screens/My/Home';
import MyLeave from '@screens/My/Leave';
import MyWish from '@screens/My/Wish';
import MySettings from '@screens/My/Settings';

import NoticeDetail from '@screens/Notice/Detail';
import NoticeList from '@screens/Notice/List';

import EventDetail from '@screens/Event/Detail';
import EventList from '@screens/Event/List';

import Terms from '@screens/Terms/Home';

import NaverLoginCallback from '@oauth/NaverLoginCallback';
import KakaoLoginCallback from '@oauth/KakaoLoginCallback';

function Router() {
  const currentUser = useSelector(state => state.user);
  const parameters = new URLSearchParams(window.location.search);
  
  let element = useRoutes([
    { path: '/',
      element: <PrivateRoute><Layout /></PrivateRoute>,
      children: [
        { index: true, element: <Navigate to={`/home/solo`} /> },
        // { path: 'register', element: currentUser?.isLoggedIn ? <Navigate to={`/`} /> : <Signup /> },
        // { path: 'mypage', element: currentUser?.isLoggedIn ? <MyPage /> : <Navigate to={`/`} /> },
        { path: 'home/:mission_type', element: <Home />},
        { path: 'rank', element: <Rank /> },
        { path: 'rank/user', element: <RankUserDetail /> },
        { path: 'rank/user/:rank_user_id', element: <RankUserDetail /> },
        { path: 'my-missions', element: <Navigate to={`/my-missions/all`} /> },
        { path: 'my-missions/:type', element: <MyMissionList /> },
        // { path: 'my/history', element: <MyMissionHistory /> },
        { path: 'spots', element: <SpotList /> },
        { path: 'spots/:spot_id', element: <SpotDetail /> },
        // { path: 'missions/:mission_id', element: <MissionDetail /> },
        // { path: 'missions/:mission_id/play/:mission_play_id', element: <MissionPlay /> },
        { path: 'missions/:type', element: <MissionList /> },
        { path: 'missions/:mission_id/view', element: <MissionDetail /> },
        { path: 'missions/:mission_id/reward/:track_id/request', element: <MissionRewardRequest /> },
        { path: 'missions/:mission_id/reward/:track_id/view', element: <MissionRewardRequestView /> },
        { path: 'missions/:mission_id/solo/play/:track_id', element: <MissionSoloPlay /> },
        { path: 'missions/:mission_id/team/play/:track_id', element: <MissionTeamPlay /> },
        { path: 'missions/:mission_id/stamp/play/:track_id', element: <MissionStampPlay /> },
        { path: 'missions/:mission_id/team/join', element: <TeamJoin /> },
        { path: 'my', element: <MyHome /> },
        { path: 'my/wish', element: <Navigate to={`/my/wish/all`} /> },
        { path: 'my/wish/:type', element: <MyWish/> },
        { path: 'notices', element: <NoticeList /> },
        { path: 'notices/:notice_id', element: <NoticeDetail /> },
        { path: 'events', element: <EventList /> },
        { path: 'events/:event_id', element: <EventDetail /> },
        { path: 'terms', element: <Terms /> },
      ]
    },
    { path: 'login', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <LoginHome /> },
    { path: 'login/email', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <LoginEmail /> },
    { path: 'login/find', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <LoginFindId /> },
    { path: 'login/findpw', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <LoginFindPassword /> },
    { path: '/oauth/naver/callback', element: <NaverLoginCallback /> },
    { path: '/oauth/kakao/callback', element: <KakaoLoginCallback /> },
    { path: 'register/term', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <RegisterTerm /> },
    { path: 'register/form', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <RegisterForm /> },
    { path: 'register/done', element: currentUser?.isLoggedIn ? <Navigate to={`/home/solo`} /> : <PrivateRoute><RegisterDone /></PrivateRoute> },
    { path: 'my/leave', element: <PrivateRoute><MyLeave /></PrivateRoute> },
    { path: 'my/settings', element: <PrivateRoute><MySettings/></PrivateRoute> },
    { path: 'not-found', element: <NotFound /> },
    { path: '*', element: <Navigate to='/home/solo' /> }
  ]);

  return element;
}

export default Router;
