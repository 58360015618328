import { useCountdown } from '@helpers/useCountdown';

const TimeCountDown = ({targetDate}) => {
  let [days, hours, minutes, seconds] = useCountdown(targetDate ? new Date(targetDate) : new Date());

  return (
    <>
      {`${hours <= 0 ? '00' : hours < 10 ? `0${hours}` : hours} : ${minutes <= 0 ? '00' : minutes < 10 ? `0${minutes}` : minutes } : ${seconds <= 0 ? '00' : seconds < 10 ? `0${seconds}` : seconds }`} 
    </>
  );
}

export default TimeCountDown;
