import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const RegisterDone = () => {
  const currentUser = useSelector((state) => state.user);

  return (
    <>
      <div className="container">
        <div className="register_done_contents">
          <h2><img src={require('@assets/img/logo_small.svg').default} alt="로고" /><span className="hide">미션프렌즈</span></h2>
          <h2 className="register_done_text"><span className="colblue">{currentUser?.user?.nickname}</span> 회원님<br/>회원가입을 축하드립니다.</h2>
          <p>미션프렌즈와 즐거운 여행을 경험하세요!</p>
        </div>
        <div className="register_done_bottom">
          <Link to="/home/solo"><button type="button" className="btn_lg">홈으로 이동</button></Link>
        </div>
      </div>
    </>
  );
}

export default RegisterDone;
