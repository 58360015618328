import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Header from '@components/Header';
import DaumPost from '@components/DaumPost'
import * as Yup from 'yup';

import { user, updateAccount } from '@actions/account';
import { setIsLoading } from '@actions/common';

const MySettings = () => {
  const dispatch = useDispatch();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(),
    nickname: Yup.string().required('닉네임을 입력해 주세요.'),
    password: Yup.string(),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], '변경할 패스워드가 다릅니다.'),
    name: Yup.string(),
    address: Yup.string(),
    address_detail: Yup.string(),
    mobile: Yup.string(),
    how: Yup.string(),
    agree_sms: Yup.boolean(),
    agree_email: Yup.boolean()
  });

  const formOptions = {
    defaultValues: {
      email: '',
      nickname: '',
      password: '',
      confirmPassword: '',
      name: '',
      address: '',
      address_detail: '',
      mobile: '',
      how: '',
      agree_sms: false, 
      agree_email: false
    },
    resolver: yupResolver(validationSchema)
  };

  const currentUser = useSelector(state => state.user);
  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  
  const formData = watch();
  
  const [isLocation, setIsLocation] = useState({
      address:'',
      zip:''
    });
    
  const [isPostPop, setIsPostPop] = useState(false);

  useEffect(() => {
    if(isLocation.address) {
      setValue('address', isLocation.address, {shouldValidate: true});
    }
  }, [isLocation])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(user(currentUser.user.id))
    .then((res) => {
      Object.keys(formData).forEach((key) => {
        setValue(key, res[key], {shouldValidate: true});
      });
      dispatch(setIsLoading(false));
    })
  }, [dispatch]);

  const handleComplete = (data) => {
    setIsPostPop(!isPostPop);
  }

  const onSubmit = (data) => {
    dispatch(updateAccount(currentUser.user.id, data))
    .then((res) => {
      toast.success(<FormattedMessage id="messages.success.update" defaultMessage="회원정보가 수정되었습니다." />, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1000, hideProgressBar: true });
    })
    .catch(message => {
      toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true
      });
    });
  }

  return (
    <>
        <Header />
        <div className="container">
          <div className="container_inner mypage_edit_flex">
            <h2 className="mb40">회원정보</h2>
            <Link to="/my/leave" className="undline">회원탈퇴</Link>
          </div>
          <form id="edit_account" onSubmit={handleSubmit(onSubmit)}>
            <fieldset className="edit_account_required container_inner">
              <label htmlFor="acc_nickname">닉네임</label>
              <div className="input_clear">
                <input type="text" id="acc_nickname" className="input_default" placeholder="미션왕" {...register("nickname")} />
                {errors && errors?.nickname?.message && <p className="input_warn" style={{display:errors?.nickname?.message ? 'block' : 'none'}}>
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.nickname?.message}
                </p>}
              </div>
              <label htmlFor="acc_email">이메일(아이디)</label>
              <div className="input_clear">
                {/* <input type="text" id="acc_email" className="" placeholder="@까지 정확하게 입력해 주세요" {...register("email")} readOnly style={{width: '100%'}}/> */}
                {/* <input type="text" id="acc_email" className="input_default" {...register("email")} disabled/> */}
                {formData.email}
              </div>
              <div className="mypage_edit_flex">
                <label htmlFor="acc_pw">비밀번호</label>
              </div>
              <div className="input_clear">
                <input type="password" id="acc_pw" className="input_default" placeholder="비밀번호(숫자+영문+특수문자 조합 8자 이상)" {...register("password")} />
                <button className="btn_clear" type="button" onClick={()=>setValue('password', '')}><span className="hide">내용 지우기</span></button>
              </div>
              <div className="mypage_edit_flex">
                <label htmlFor="acc_pw">비밀번호(비밀번호 변경시)</label>
              </div>
              <div className="input_clear">
                <input type="password" id="acc_re_pw" className="input_default" placeholder="비밀번호(숫자+영문+특수문자 조합 8자 이상) 재입력해 주세요." {...register("confirmPassword")} />
                <button className="btn_clear" type="button" onClick={()=>setValue('confirmPassword', '')}><span className="hide">내용 지우기</span></button>
                {errors && errors?.confirmPassword?.message && <p className="input_warn" style={{display:errors?.confirmPassword?.message ? 'block' : 'none'}}>
                  <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.confirmPassword?.message}
                </p>}
              </div>
              {/* <div className="mypage_edit_flex">
                <label htmlFor="acc_phone">휴대폰 번호</label>
              </div>
              <input type="text" id="acc_phone" className="input_default" placeholder="010-1234-1234" {...register('mobile',{
                    onChange: (e)=>{
                      e.target.value = e.target.value.replace(/[^0-9]/g,'');
                      e.target.value = e.target.value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
                    }})} /> */}
            </fieldset>
            <div className="register_account_optional_bg">
              <div className="container_inner">
                <p className="mb15"><strong>추가정보 (선택)</strong></p>
                <fieldset className="register_account_optional">
                  <label htmlFor="acc_email" style={{display:'block',marginBottom:'10px'}}>이름</label>
                  <div className="input_clear">
                    <input type="text" id="name" className="input_default" placeholder="이름" {...register("name")} />
                    <button className="btn_clear" type="button" onClick={()=>setValue('name', '')}><span className="hide">내용 지우기</span></button>
                  </div>
                  <div className="input_address_wrap mb30">
                    <label htmlFor="acc_add1">주소</label>
                    <div className="input_postcode">
                      <input type="text" id="acc_add2" className="input_default" {...register("address")} value={formData.address} placeholder='주소를 검색해 입력해 주세요.'  readOnly/>
                      <button type='button' onClick={handleComplete}>검색</button>
                      {isPostPop && <DaumPost location={isLocation} setLocation={setIsLocation}></DaumPost>}
                    </div>
                    <input type="text" id="acc_add3" className="input_default" {...register("address_detail")} placeholder='상세주소를 입력해 주세요'/>
                  </div>
                  <p className="mb15"><strong>본 서비스는 어떻게 알게 되셨나요?</strong></p>
                  <select name="acc_how" id="acc_how" className="select_default" {...register("how")}>
                    <option value=''>선택해 주세요</option>
                    <option value="0">인터넷 뉴스,기사</option>
                    <option value="1">신문/잡지</option>
                    <option value="2">블로그, 페이스북, 인스타그램</option>
                    <option value="3">관련 기관을 통해</option>
                    <option value="4">홍보 책자 및 팸플릿</option>
                    <option value="5">지인소개</option>
                    <option value="6">기타</option>
                  </select>
                  <div className="double_wrap">
                    <div>
                      <input type="checkbox" name="acc_agree_sms" id="acc_agree_sms" className="chk_default" {...register("agree_sms")} value={true}/>
                      <label htmlFor="acc_agree_sms">문자 수신 여부</label>
                    </div>
                    <div style={{whiteSpace:'nowrap'}}>
                      <input type="checkbox" name="acc_agree_email" id="acc_agree_email" className="chk_default" {...register("agree_email")} value={true}/>
                      <label htmlFor="acc_agree_email">이메일 수신 여부</label>
                    </div>
                  </div>
                </fieldset>
                <small>* 문자 및 이메일 수신에 체크 시 경품 및 이벤트 상품을 안내받으실 수 있습니다.</small>
                <button type='submit' className="btn_lg">정보 수정</button>
              </div>
            </div>
          </form>
      </div>
    </>
  );
}

export default MySettings;
