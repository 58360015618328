import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import { Link, useParams } from 'react-router-dom';

import * as Yup from 'yup';

import { getMyMission } from '@actions/track';
import { setIsLoading } from '@actions/common';
import TimeCountDown from '@components/TimeCountDown'
import Moment from 'react-moment';
import moment from 'moment'
import * as helper from '@/helper'
import LottieAnimation from '@components/LottieAnimation';

const MissionList = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [filteredMissionData, setFilteredMissionData] = useState(null);

  useEffect(() => {
    if(!['all', 'play', 'success', 'fail'].includes(params.type)){
      history.navigate('my-missions/all', { replace: true });
    } else {
      dispatch(setIsLoading(true));
      dispatch(getMyMission())
        .then((res) => {
          if (params.type === 'play') {
            setFilteredMissionData(res.filter(item => item.status === 'play'));
          } else if (params.type === 'success') {
            setFilteredMissionData(res.filter(item => item.status === 'close' && item.result));
          } else if(params.type === 'fail') {
            setFilteredMissionData(res.filter(item => item.status === 'close' && !item.result));
          } else {
            setFilteredMissionData(res);
          }
          dispatch(setIsLoading(false));
        })
        .catch((err) => {
          toast.error(err.message);
          dispatch(setIsLoading(false));
        })
    }

  }, [params.type]);

  return (
    <>
      <div className="container">
    <div className="container_inner">
      <h2>나의 미션</h2>
    </div>
    <div className="search_cate_wrap">
      <Link to={'/my-missions/all'} className={params.type === 'all' ? 'active' : ''}>전체</Link>
      <Link to={'/my-missions/play'} className={params.type === 'play' ? 'active' : ''}>미션 참여중</Link>
      <Link to={'/my-missions/success'} className={params.type === 'success' ? 'active' : ''}>미션성공</Link>
      <Link to={'/my-missions/fail'} className={params.type === 'fail' ? 'active' : ''}>미션실패</Link>
    </div>
    <div className="my_mission_lists order_lists container_fit">
      {filteredMissionData && filteredMissionData.map(item=><>
        {item.mission.type === 'solo' && <div className="order_list">
          <div className="order_list_inner">
            <div className="my_missoin_cate_wrap">
              <p className="detail_cate"><img src={require('@assets/img/icon_h1_green.png')} /><strong>1인 미션 투어</strong></p>
              {item.status === 'play' && <strong>남은 시간&nbsp;&nbsp;<span className="colred"><TimeCountDown targetDate={new Date(item.end_date)}/></span></strong>}
            </div>
            <div className="mission_contents_wrap">
              <p><strong>{item.mission.title}</strong></p>
              <dl className="detail_mission_info">
                <dt>참여기간</dt>
                <dd>{item.mission.is_unlimit_date ? '상시' : <><Moment format='YYYY.MM.DD'>{item.mission.from_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{item.mission.to_date}</Moment></>}</dd>
                <dt>제한시간</dt>
                <dd>{item.mission.limit_time}시간</dd>
                {item.status === 'close' && <>
                <dt>수행시간</dt>
                <dd>{helper.secondsToTime(moment(item.close_date).diff(moment(item.play_date), 'seconds'))}</dd>
                </>}
                {/* <dt>미션 개수</dt>
                <dd>5</dd> */}
              </dl>
            </div>
            {/* <div className="mission_progress_wrap my_mission_progress_wrap">
              <div className="mission_progress_bar">
                <span className="mission_progress_fill" style={{width:'25%'}}></span>
              </div>
              <small><strong className="colgreen">2</strong> / 5&nbsp;</small>
            </div> */}
            <div className="my_mission_btn_wrap">
              {/* 좌측에 버튼이 있을 경우에만 my_mission_links 내에 버튼 추가 */}
              <div className="my_mission_links">
                {item.status === 'close' && item.result && !item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/request`} className="btn_default_r5_line" data-line="red">경품신청</Link>}
                {item.status === 'close' && item.result && item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/view`} className="btn_default_r5_line" data-line="gray">경품신청 내역</Link>}
              </div>
              {/* 좌측에 버튼이 있을 경우에만 my_mission_links 내에 버튼 추가 끝 */}
              {/* http://localhost:3000/missions/63a513c22cd1e84a0ff92724/solo/play/63bac2516b4d26377ee2aabf */}
              {item.status === 'play' && <button className="btn_default_r5" data-bg="blue"><Link to={`/missions/${item.mission._id}/solo/play/${item._id}`}>미션 참여중</Link></button>}
              {item.status === 'close' && item.result && <button className="btn_default_r5">미션 성공</button>}
              {item.status === 'close' && !item.result && <button className="btn_default_r5" data-bg="red">미션 실패</button>}
            </div>
          </div>
        </div>}
        {item.mission.type === 'team' && <div className="order_list">
          <div className="order_list_inner">
            <div className="my_missoin_cate_wrap">
              <p className="detail_cate" data-name="team"><img src={require('@assets/img/icon_h2_blue.png')} /><strong>단체 미션 투어</strong></p>
              {item.status === 'play' && <strong>남은 시간&nbsp;&nbsp;<span className="colred"><TimeCountDown targetDate={new Date(item.end_date)}/></span></strong>}
            </div>
            <div className="mission_contents_wrap">
              <p><strong>{item.mission.title}</strong></p>
              <dl className="detail_mission_info">
                <dt>참여기간</dt>
                <dd>{item.mission.is_unlimit_date ? '상시' : <><Moment format='YYYY.MM.DD'>{item.mission.from_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{item.mission.to_date}</Moment></>}</dd>
                <dt>제한시간</dt>
                <dd>{item.mission.limit_time}시간</dd>
                <dt>팀 인원</dt>
                <dd>{item.mission.max_team}팀 / {item.mission.max_team_persion}인</dd>
                {item.status === 'close' && <>
                <dt>수행시간</dt>
                <dd>{helper.secondsToTime(moment(item.close_date).diff(moment(item.play_date), 'seconds'))}</dd>
                </>}
                {/* <dt>미션 개수</dt>
                <dd>5</dd> */}
              </dl>
            </div>
            {/* <div className="mission_progress_wrap my_mission_progress_wrap">
              <div className="mission_progress_bar">
                <span className="mission_progress_fill"></span>
              </div>
              <small><strong className="colgreen">2</strong> / 5&nbsp;</small>
            </div> */}
            <div className="my_mission_btn_wrap">
              <div className="my_mission_links">
                {/* <button className="btn_default_r5_line">재도전</button> */}
                {item.status === 'close' && item.result && !item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/request`} className="btn_default_r5_line" data-line="red">경품신청</Link>}
                {item.status === 'close' && item.result && item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/view`} className="btn_default_r5_line" data-line="gray">경품신청 내역</Link>}
              </div>
              {/* <button className="btn_default_r5" data-bg="red"><Link to="/my/history">미션 실패</Link></button> */}
              {item.status === 'play' && <button className="btn_default_r5" data-bg="blue"><Link to="/missions/play">미션 참여중</Link></button>}
              {item.status === 'close' && item.result && <button className="btn_default_r5">미션 성공</button>}
              {item.status === 'close' && !item.result && <button className="btn_default_r5" data-bg="red">미션 실패</button>}
            </div>
          </div>
        </div>}
        {/* <button className="btn_default_r5" data-bg="red"><Link to="/my/history">미션 실패</Link></button> */}

        {item.mission.type === 'stamp' && <div className="order_list">
          <div className="order_list_inner">
            <div className="my_missoin_cate_wrap">
              <p className="detail_cate" data-name="stamp"><img src={require('@assets/img/icon_h3_red.png')} /><strong>스탬프 투어</strong></p>
              {item.status === 'play' && <strong>남은 시간&nbsp;&nbsp;<span className="colred"><TimeCountDown targetDate={new Date(item.end_date)}/></span></strong>}
            </div>
            <div className="mission_contents_wrap">
              <p><strong>{item.mission.title}</strong></p>
              <dl className="detail_mission_info">
                <dt>참여기간</dt>
                <dd>{item.mission.is_unlimit_date ? '상시' : <><Moment format='YYYY.MM.DD'>{item.mission.from_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{item.mission.to_date}</Moment></>}</dd>
                <dt>제한시간</dt>
                <dd>{item.mission.limit_time}시간</dd>
                {item.status === 'close' && <>
                <dt>수행시간</dt>
                <dd>{helper.secondsToTime(moment(item.close_date).diff(moment(item.play_date), 'seconds'))}</dd>
                </>}
                {/* <dt>미션 개수</dt>
                <dd>7</dd> */}
              </dl>
            </div>
            <div className="mission_progress_wrap my_mission_progress_wrap">
              <div className="mission_progress_bar">
                <span className="mission_progress_fill" style={{width: `${item.answer_count/item.mission.stamp_image_divide_num * 100}%`}}></span>
              </div>
              <small><strong className="colgreen">{item.answer_count}</strong> / {item.mission.stamp_image_divide_num}&nbsp;</small>
            </div>
            <div className="my_mission_btn_wrap">
              <div className="my_mission_links">
                {/* <button className="btn_default_r5_line">재도전</button> */}
                {item.status === 'close' && item.result && !item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/request`} className="btn_default_r5_line" data-line="red">경품신청</Link>}
                {item.status === 'close' && item.result && item.is_request_giveaway && <Link to={`/missions/${item.mission._id}/reward/${item._id}/view`} className="btn_default_r5_line" data-line="gray">경품신청 내역</Link>}
              </div>
              {/* http://localhost:3000/missions/63a51ed22cd1e84a0ff928a5/stamp/play/63ba9c39152e577cd1e05762 */}
              {item.status === 'play' && <button className="btn_default_r5" data-bg="blue"><Link to={`/missions/${item.mission._id}/stamp/play/${item._id}`}>미션 참여중</Link></button>}
              {item.status === 'close' && item.result && <button className="btn_default_r5">미션 성공</button>}
              {item.status === 'close' && !item.result && <button className="btn_default_r5" data-bg="red">미션 실패</button>}
            </div>
          </div>
        </div>}
      </>)}
      {params.type === 'all' && filteredMissionData && filteredMissionData.length === 0 && <div className="no_data">
        <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
        <span>참여한 미션이 없습니다.</span>
      </div>}
      {params.type === 'play' && filteredMissionData && filteredMissionData.length === 0 && <div className="no_data">
        <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
        <span>참여중인 미션이 없습니다.</span>
      </div>}
      {params.type === 'success' && filteredMissionData && filteredMissionData.length === 0 && <div className="no_data">
        <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
        <span>아직 성공한 미션이 없습니다.</span>
      </div>}
      {params.type === 'fail' && filteredMissionData && filteredMissionData.length === 0 && <div className="no_data">
        <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
        <span>아직 실패한 미션이 없습니다.</span>
      </div>}
    </div>
  </div>
    </>
  );
}

export default MissionList;
