import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '@helpers/history';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import config from '@config';

import { events } from '@actions/board';
import { setIsLoading } from '@actions/common';

const EventList = () => {
  const dispatch = useDispatch();
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(events({
      page: currentPage,
      limit: pageLimit,
    }))
    .then((res) => {
      setTotalPageCount(1);
      setTotalItemCount(res.data.length);
      setCurrentItems(res.data);
      dispatch(setIsLoading(false));
    })
  }, []);
  
  return (
    <>
      <div className="container">
        <div className="container_inner">
          <h2 className="mb20">이벤트</h2>
          <div className="event_wrap">
            <ul>
              {
                currentItems && currentItems.map((item, index) => <li>
                    <Link to={`/events/${item._id}`}>
                      <div className="event_item__img">
                        {item?.cover_image?.server_filename ? <img src={`${config.apiServerHost}/spots/attachment/${item?.cover_image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/738x448'}/> : <img src='https://via.placeholder.com/738x448' alt="" width={'100%'}/>}
                      </div>
                      <h3 className="event_item__title">{item.title}</h3>
                      <div className="event_item__date">
                        {item.is_unlimited_date ? '상시' : <small className="coldg"><Moment format='YYYY.MM.DD'>{item.event_start_date}</Moment> ~ <Moment format='YYYY.MM.DD'>{item.event_end_date}</Moment></small>}
                        {!item.is_unlimited_date && <h2>
                          {
                            moment(item.event_start_date).diff(moment(new Date()), 'days') === 0 && 'D-DAY'
                          }
                          {
                            moment(item.event_start_date).diff(moment(new Date()), 'days') > 0 && 'D - ' + moment(item.event_start_date).diff(moment(new Date()), 'days')
                          }
                          {
                            moment(item.event_start_date).diff(moment(new Date()), 'days') < 0 && 'D + ' + moment(item.event_start_date).diff(moment(new Date()), 'days') * -1
                          }
                        </h2>}
                      </div>
                    </Link>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default EventList;
