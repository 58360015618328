import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { event } from '@actions/board';
import { setIsLoading } from '@actions/common';

import Moment from 'react-moment';

const EventDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [eventData, setEventData] = useState(null);
  
  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(event(params.event_id))
      .then((res) => {
        setEventData(res);
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
        history.navigate('/events', { replace: true});
      })

  }, []);
  
  return (
    <>
      {eventData && <div className="container">
        <div className="notice_view_top">
          <h2>{eventData.title}</h2>
          <small className="coldg"><Moment format='YYYY.MM.DD'>{eventData.created}</Moment></small>
        </div>
        <div className="container_inner">
          <div className="notice_view_text" dangerouslySetInnerHTML={{__html: eventData.body}} />
        </div>
      </div>}
    </>
  );
}

export default EventDetail;
