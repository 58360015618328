import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

import { notices } from '@actions/board';
import { setIsLoading } from '@actions/common';

const NoticeList = () => {
  const dispatch = useDispatch();
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(notices({
      page: currentPage,
      limit: pageLimit,
    }))
    .then((res) => {
      setTotalPageCount(1);
      setTotalItemCount(res.data.length);
      setCurrentItems(res.data);
      dispatch(setIsLoading(false));
    })
  }, []);
  
  return (
    <>
      <div className="container">
        <div className="container_inner"><h2 className="mb20">공지사항</h2></div>
        <div className="notice_wrap">
          <ul>
            {
              currentItems && currentItems.map((item, index) => item.is_notice ? 
                <li className="active" key={item._id}>
                  <Link to={`/notices/${item._id}`}>
                    <p className="notice_title">
                      <span className="notice_pin">공지</span>
                      {item.title}
                    </p>
                    <small className="coldg"><Moment format='YYYY.MM.DD'>{item.from_date}</Moment></small>
                  </Link>
                </li>:
                <li key={item._id}>
                  <Link to={`/notices/${item._id}`}>
                    <p className="notice_title">
                      {item.title}
                    </p>
                    <small className="coldg"><Moment format='YYYY.MM.DD'>{item.from_date}</Moment></small>
                  </Link>
                </li>
              )    
            }
          </ul>
        </div>
      </div>
    </>
  );
}

export default NoticeList;
