import { NavLink } from 'react-router-dom';
import history from '@helpers/history';

function Header(props) {
  return (
    <nav className="bottom_menus">
      <div className="bottom_menu">
        <NavLink to="/" className={history.location.pathname.toLowerCase().startsWith('/home') ? 'active' : ''}>
          <span className="bottom_menu__img"><span className="hide">이미지 들어가는 곳</span></span>
          <p>홈</p>
        </NavLink>
      </div>
      <div className="bottom_menu">
        <NavLink to="/rank">
          <span className="bottom_menu__img"><span className="hide">이미지 들어가는 곳</span></span>
          <p>랭킹</p>
        </NavLink>
      </div>
      <div className="bottom_menu">
        <NavLink to="/my-missions/all">
          <span className="bottom_menu__img"><span className="hide">이미지 들어가는 곳</span></span>
          <p>나의 미션</p>
        </NavLink>
      </div>
      <div className="bottom_menu">
        <NavLink to="/spots">
          <span className="bottom_menu__img"><span className="hide">이미지 들어가는 곳</span></span>
          <p>관광지</p>
        </NavLink>
      </div>
      <div className="bottom_menu">
        <NavLink to="/my">
          <span className="bottom_menu__img"><span className="hide">이미지 들어가는 곳</span></span>
          <p>My</p>
        </NavLink>
      </div>
    </nav>
  );
}

export default Header;
