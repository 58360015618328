import axiosInstance from '@services/api';
import { logout } from '@actions/account';
import history from '@helpers/history';

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = store.getState().auth?.accessToken;
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const { dispatch, selector } = store;

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      if(err.response.status === 401){
        dispatch(logout()).then(() => history.navigate('/',{replace: true}));
      }
      return Promise.reject(err);
    }
  )
}

export default setup;
