import api from '@/services/api';
import queryString from 'query-string';

class MissionService {
  createMission(params) {
    return api.post('/missions', params)
  }

  mission(mission_id) {
    return api.get(`/missions/${mission_id}`)
  }

  missions(params) {
    return api.get(`/missions?${queryString.stringify(params)}`)
  }

  updateMission(mission_id, params) {
    return api.put(`/missions/${mission_id}`, params)
  }

  deleteMission(mission_id) {
    return api.delete(`/missions/${mission_id}`)
  }

  quizzes(mission_id) {
    return api.get(`/missions/${mission_id}/quiz`)
  }

  like(mission_id) {
    return api.put(`/missions/${mission_id}/like`)
  }
}

export default new MissionService();
