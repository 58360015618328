import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import history from '@helpers/history';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import LottieAnimation from '@components/LottieAnimation';
import TimeCountDown from '@components/TimeCountDown'
import useWatchLocation from '@helpers/useWatchLocation'
import { getMyPlayMission, createQuizAnswer } from '@actions/track';
import { setIsLoading } from '@actions/common';
import * as helper from '@/helper'
import useInterval  from '@helpers/useInterval';

const MissionSoloPlay = () => {
  const dispatch = useDispatch();
  const params = useParams();
  
  const [isHintPop, setIsHitPop] = useState(false);
  const [isMissionCorrect, setIsMissionCorrect] = useState(false);
  const [isMissionWrong, setIsMissionWrong] = useState(false);
  const [isMissionDone, setIsMissionDone] = useState(false);
  const [isMissionFail, setIsMissionFail] = useState(false);
  const [isExitMissionConfirm, setIsExitMissionConfirm] = useState(false);
  
  const [myImg, setMyImg] = useState('');
  const [missionData, setMissionData] = useState(null);
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [showCurrentQuiz, setShowCurrentQuiz] = useState(false);
  const [tryCount, setTryCount] = useState(0);

  const { isLoading } = useSelector((state) => state.common);

  const options = {
    enableHighAccuracy: true,
    timeout: 1000 * 60 * 1, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
    // maximumAge: 1000 * 3600 * 24, // 24 hour
    // timeout: 5000,
    maximumAge: 0, // 24 hour
  }
  const { location, cancelLocationWatch, error } = useWatchLocation(options);
  
  const validationSchema = Yup.object().shape({
    answer: Yup.mixed().required('정답을 입력해 주세요.'),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const formData = watch();

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setValue('answer', file);
    setMyImg(URL.createObjectURL(file));
  };

  useEffect(() => {
    if(error == 'User denied Geolocation'){
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: true})
    } else {
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: false})
    }

    return () => {
      dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: false})
      cancelLocationWatch();
    }
  }, [location, error])

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(getMyPlayMission(params.track_id))
    .then((res) => {
      dispatch(setIsLoading(false));
      if(res.status === 'close') {
        history.navigate(`/missions/${params.mission_id}/view`, {replace: true})  
      } else {
        if(res.answer.length === 0) {
          setCurrentQuiz(res.mission.quiz[0])
        } else {
          const latestQuiz = res.answer[res.answer.length - 1].quiz
          const latestAnswer = res.answer[res.answer.length - 1].answer

          if(latestQuiz.type === 'essay') {
            const next_quiz = _.find(res.mission.quiz, {id: latestQuiz.essay.forward_index});  
            setCurrentQuiz(next_quiz)
          } else if(latestQuiz.type === 'image') {
            const next_quiz = _.find(res.mission.quiz, {id: latestQuiz.image.forward_index});  
            setCurrentQuiz(next_quiz)
          } else if(latestQuiz.type === 'ox') {
            const correct_answer = _.find(latestQuiz.ox, {_id: latestAnswer});  
            const next_quiz = _.find(res.mission.quiz, {id: correct_answer.forward_index});  
            setCurrentQuiz(next_quiz)
          } else {
            const correct_answer = _.find(latestQuiz.multiple, {_id: latestAnswer});  
            const next_quiz = _.find(res.mission.quiz, {id: correct_answer.forward_index});  
            console.log(next_quiz)
            setCurrentQuiz(next_quiz)
          }
        }
        setTryCount(0);
        setShowCurrentQuiz(false)
        setMissionData(res)
      }
    })
    .catch(message => {
      dispatch(setIsLoading(false));
      history.navigate(`/missions/${params.mission_id}/view`, {replace: true})  
    })
  }, []);

  useEffect(() => {
    if(!showCurrentQuiz && currentQuiz?.coordinate?.lat && currentQuiz?.coordinate?.lng && location?.latitude && location?.longitude){
      if(helper.getDistanceFromLatLonInKm(currentQuiz.coordinate.lat, currentQuiz.coordinate.lng, location.latitude, location.longitude) < 0.1){
        if(navigator.vibrate){
          navigator.vibrate([100, 50, 100, 50, 100]);
        }
        setShowCurrentQuiz(true)
      }
    }
  }, [location]);

  useInterval(() => {
    dispatch(getMyPlayMission(params.track_id))
    .then((res) => {
      if(!isLoading && !isMissionFail && !isMissionDone && !isMissionWrong && !isMissionCorrect){
        if(res.status === 'close') {
          res.result ? setIsMissionDone(true) : setIsMissionFail(true)
        } else if(currentQuiz && res.answer.length > 0) {
          const latestQuiz = res.answer[res.answer.length - 1].quiz
          const latestAnswer = res.answer[res.answer.length - 1].answer
          let next_quiz = ''
          
          if(latestQuiz.type === 'essay') {
            next_quiz = _.find(res.mission.quiz, {id: latestQuiz.essay.forward_index});  
          } else if(latestQuiz.type === 'image') {
            next_quiz = _.find(res.mission.quiz, {id: latestQuiz.image.forward_index});  
          } else if(latestQuiz.type === 'ox') {
            const correct_answer = _.find(latestQuiz.ox, {_id: latestAnswer});  
            next_quiz = _.find(res.mission.quiz, {id: correct_answer.forward_index});  
          } else {
            const correct_answer = _.find(latestQuiz.multiple, {_id: latestAnswer});  
            next_quiz = _.find(res.mission.quiz, {id: correct_answer.forward_index});  
          }
  
          if(next_quiz && currentQuiz?.id !== next_quiz?.id) {
            setCurrentQuiz(next_quiz)
            setTryCount(0);
            setShowCurrentQuiz(false)
            // setMissionData(res)
            setIsMissionCorrect(true)
          }
        }
      }
    })
  }, 1000);

  const onSubmit = (data) => {
    if(currentQuiz.type === 'multiple' || currentQuiz.type === 'ox' || currentQuiz.type === 'essay') {
      const is_correct_answer = currentQuiz.type === 'essay' ? _.includes(currentQuiz.essay.answer.split(','), data.answer) : _.some(currentQuiz[currentQuiz.type], {answer: true, _id: data.answer});

      if(!is_correct_answer){ //노답인 경우
        setIsMissionWrong(true)
        setTryCount(tryCount + 1)
      } else {
        dispatch(setIsLoading(true));

        dispatch(createQuizAnswer(params.track_id, currentQuiz.id, {
          answer: data.answer,
          try_count: tryCount
        }))
        .then(res => {
          if(res.status == 'close'){
            res.result ? setIsMissionDone(true) : setIsMissionFail(true)
          } 
          else {
            !res.next_quiz ? setIsMissionDone(true) : console.log('')
            setCurrentQuiz(res.next_quiz)
            setShowCurrentQuiz(false)
          }
          dispatch(setIsLoading(false));
        })
        .catch(message => {
          dispatch(setIsLoading(false));
          toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1000,
            hideProgressBar: true
          });
        })
        
      }
    } else if(currentQuiz.type === 'image') {
      const formData = new FormData();
      
      formData.append("attachment", data.answer);
      formData.append("try_count", tryCount);

      dispatch(setIsLoading(true));

      dispatch(createQuizAnswer(params.track_id, currentQuiz.id, formData))
      .then(res => {
        dispatch(setIsLoading(false));
        if(res.status == 'close'){
          res.result ? setIsMissionDone(true) : console.log('')
        } else {
          setCurrentQuiz(res.next_quiz)
          setShowCurrentQuiz(false)
        }
      })
      .catch(message => {
        dispatch(setIsLoading(false));

        toast.error(<FormattedMessage id={`messages.error.${message}`} defaultMessage={message} />, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 1000,
          hideProgressBar: true
        });
      })
      setMyImg('')
    }
    setValue('answer', '')
  }
  
  return (
    <>
      {missionData && !isMissionFail && !isMissionDone && <div className="container">
          {/* 알림이 있을 경우에만 노출되는 메시지 */}
          {/* <div className="mission_alert active">
            ★ 빨리 빨리 클리어 하자! 팀이 미션을 완료하였습니다.
          </div> */}
          {/* 알림이 있을 경우에만 노출되는 메시지 끝 */}
        <form onSubmit={handleSubmit(onSubmit)}> 
        <div className="container mission_team_item_container">
          {/* <div className="mission_lists_countdown_wrap"> */}
          <div className="detail_countdown_wrap">
            <div className="detail_count_down_left">
              <img src={require('@assets/img/icon_time_wh.png')} />
              <small>남은 시간</small>
              &nbsp;<strong><TimeCountDown targetDate={new Date(missionData.end_date)}/></strong>
            </div>
            {/* <div className="detail_countdown_right">
              <small>AB457-DG542-GH333</small>
            </div> */}
          </div>
          <div className="container_inner">
            {/* 모든 미션 유형의 공통 상단 */}
            <div className="mission_item_q mb40">
              {/* <span className="mission_mission">Mission</span> */}
              <h2>{currentQuiz?.title}</h2>
              <p dangerouslySetInnerHTML={{__html: currentQuiz?.description}} />
            </div>
            {
            !showCurrentQuiz ? 
            <div style={{textAlign:'center'}}>
              <LottieAnimation width="300px" data={require('@/assets/lottie/79913-walk-man.json')}/>
              <span>미션장소로 이동하여 미션을 수행하세요.</span>
            </div> : <>
            {/* 모든 미션 유형의 공통 상단 끝*/}
            {/* 미션 시작하면 나타나는 유형별 각 문항 */}
            {/***** 질문에 사진이 첨부된 주관식 인풋 유형 ******/}
            {currentQuiz.type === 'essay' && <div id="mission_item_type1">
              <h2 className='mb15'>{currentQuiz?.question}</h2>
              <div className="mission_item_type1">
                <div className="input_clear">
                  <input type="text" className="input_default" placeholder="정답을 입력해 주세요" {...register('answer')}/>
                </div>
              </div>
            </div>}
            {/***** 질문에 사진이 첨부된 주관식 인풋 유형 끝 *****/}
            {/***** 사진/동영상 첨부하는 유형 *****/}
            {currentQuiz.type === 'image' && <div>
              <div className="mission_item_type2">
                <h2 className='mb15'>{currentQuiz?.question}</h2>
                <label htmlFor="mission_attach" className="mission_attach">
                  <img src={require('@assets/img/icon_media_green.png')} className="mr10" />
                  사진 첨부하기
                </label>
                <input type="file" name="mission_attach" id="mission_attach" {...register('answer')} onChange={onImageChange}  accept="capture=camera,image/*" />
                {/* 동영상이냐 이미지냐에 따라 해당 미디어만 노출 */}
                {myImg && <img src={myImg} style={{marginTop:'10px'}}/>}
              </div>
            </div>}
            {/***** 사진/동영상 첨부하는 유형 끝 *****/}
            {/***** 택1 미션 *****/}
            {currentQuiz.type === 'multiple' && <div>
              <h2 className='mb15'>{currentQuiz?.question}</h2>
              <div className="mission_item_type3">
                {
                  _.filter(currentQuiz.multiple, r=>r.text).map((item, idx)=><label htmlFor={`mission_answer[${idx}]`} className={formData.answer === item._id ? `active` : ''} key={item._id}>{item.text}
                    <input type="radio" name="mission_answer" id={`mission_answer[${idx}]`} {...register('answer')} value={item._id} className="chk_circle"/>
                  </label>)
                }
              </div>
            </div>}
            {/***** 택1 미션 끝 *****/}
            {/***** OX 미션 *****/}
            {currentQuiz.type === 'ox' && <div>
              <h2 className='mb15'>{currentQuiz?.question}</h2>
              <div className="mission_item_type4">
                <input type="radio" name="mission_ox" id="mission_o" {...register('answer')} value={currentQuiz.ox[0]._id}/>
                <input type="radio" name="mission_ox" id="mission_x" {...register('answer')} value={currentQuiz.ox[1]._id}/>
              </div>
            </div>}
            {/***** OX 미션 끝*****/}
            </>}
          </div>
        </div>
        {showCurrentQuiz && <div className="play_bottom">
          {/* <div>
            <span className="hint_count">남은 힌트 <strong>5</strong></span>
            <button className="btn_default_r5_line btn_hint" onClick={()=>setIsHitPop(true)}>힌트 보기</button>
          </div> */}
          <button type='submit' className={`btn_lg ${formData && !formData.answer ? 'bg_gray' : ''}`} disabled={formData && !formData.answer}>{'제출하기'}</button>
        </div>}
        </form>
      </div>}
      <div id="mission_hint" className="modal fade" style={{display: isHintPop? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <h2 className="mb10 colgreen">힌트</h2>
                <strong>송정역에는 유명한 할매갈비집이 있어요!</strong>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn_default" onClick={()=>setIsHitPop(false)}>확인</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mission_correct" className="modal fade" style={{display: isMissionCorrect? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <strong><span className="colred">축하드립니다.</span><br/>미션을 완료하셨습니다.</strong>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn_default" onClick={()=>setIsMissionCorrect(false)}>다음미션 진행</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mission_wrong" className="modal fade" style={{display: isMissionWrong? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <strong>오답입니다.<br/>다시 입력해 주세요.</strong>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn_default" onClick={()=>setIsMissionWrong(false)}>확인</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mission_done" className="modal fade" style={{display: isMissionDone? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <h2 className="mb10 colred">미션 성공</h2> 
                <strong>축하드립니다.<br/>미션을 모두 완료하셨습니다.<br/>경품을 신청해 주세요!</strong>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_pop_yes" onClick={()=>history.navigate(`/`, {replace: true})  }>홈으로 이동</button>
              <button type="button" className="btn_pop_no"  onClick={()=>history.navigate(`/missions/${params.mission_id}/reward/${params.track_id}/request`, {replace: true})  }>경품 받기</button>
            </div>
          </div>
        </div>
      </div>
      <div id="mission_fail" className="modal fade" style={{display: isMissionFail? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <h2 className="mb10 colgreen">미션 실패</h2>
                <strong>아쉽게도 미션에 실패하셨습니다.<br/>참여해 주셔서 감사합니다.</strong>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_pop_yes" onClick={()=>history.navigate(`/`, {replace: true})  }>홈으로 이동</button>
              {/* <button type="button" className="btn_pop_no" onClick={()=>setIsMissionFail(false)}>닫기</button> */}
            </div>
          </div>
        </div>
      </div>
      <div id="mission_done" className="modal fade" style={{display: isExitMissionConfirm? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <h2 className="mb10 colred">미션 종료</h2> 
                <strong>미션을 종료하시겠습니까?</strong>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_pop_yes" onClick={()=>alert('미션종료')}>종료할게요</button>
              <button type="button" className="btn_pop_no"  onClick={()=>setIsExitMissionConfirm(false)}>아니요</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionSoloPlay;
