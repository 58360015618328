import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import history from '@helpers/history';
import _ from 'lodash';
import Header from '@components/Header';
import * as Yup from 'yup';

import { deleteAccount } from '@actions/account';
import { Link } from 'react-router-dom'; 
import { setIsLoading } from '@actions/common';
import { logout } from '@actions/auth';

const MyLeave = () => {
  const [isMemberLeave, setIsMemberLeave] = useState(false);
  const dispatch = useDispatch();
  
  const currentUser = useSelector((state) => state.user);

  const validationSchema = Yup.object().shape({
    message: Yup.string(),
  });

  const formOptions = {
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(validationSchema)
  };

  const { register, handleSubmit, setValue, reset, setError, formState, watch } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const formData = watch();

  const onLeave = () => {
    if(!formData.why_leave){
      setError(`why_leave`,{ message: '탈퇴 사유를 선택해 주세요' });
    } else {
      setError(`why_leave`,{ message: '' });

      dispatch(setIsLoading(true));
      dispatch(deleteAccount(currentUser.user.id, { why_leave: formData.why_leave, why_leave_text: formData.why_leave_text }))
      .then((res) => {
        history.navigate(`/`, { replace: true});
        dispatch(setIsLoading(false));
        dispatch(logout());
      })
      .catch((err) => {
        dispatch(setIsLoading(false));
      })
    }
  }

  return (
    <>
    <Header />
      <div className="container">
        <div className="container_inner">
          <h2 className="mb20">회원탈퇴</h2>
          <form id="delete_account">
            <fieldset className="delete_account_required">
              <label htmlFor="why_leave">탈퇴 사유</label>
              <select name="why_leave" id="why_leave" className="select_default" {...register("why_leave")}>
                <option value=''>탈퇴 사유를 선택해 주세요</option>
                <option value='사용을 하지 않아서'>사용을 하지 않아서</option>
                <option value='기타'>기타</option>
              </select>
              {errors && errors?.why_leave?.message && <p className="input_warn mb30 text-center">
                <img src={require('@assets/img/icon_warning.png')} alt="경고" className="mr5"/>{errors?.why_leave?.message}
              </p>}
              <textarea name="why_leave_text" id="why_leave_text" rows="5" placeholder="사유를 입력해 주세요." {...register("why_leave_text")}></textarea>
            </fieldset>
            <small>* 탈퇴 신청을 하셔도 소비자 관련 법률상 이용 데이터는 서버에 1년 보관 후 자동삭제 처리 됩니다.</small>
            <button type="button" className="btn_lg" onClick={() => setIsMemberLeave(true)}>회원탈퇴</button>
          </form>
        </div>
      </div>
      <div id="member_leave" className="modal fade" style={{display: isMemberLeave ? 'block' : 'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="modal_text_center">
                <strong>정말 회원을 탈퇴하시겠습니까?</strong>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_pop_yes" onClick={()=>onLeave()}>회원탈퇴</button>
              <button type="button" className="btn_pop_no" onClick={()=>setIsMemberLeave(false)}>회원유지</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyLeave;
