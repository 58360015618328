import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { notice } from '@actions/board';
import { setIsLoading } from '@actions/common';

import Moment from 'react-moment';

const NoticeDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [noticeData, setNoticeData] = useState(null);
  
  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(notice(params.notice_id))
      .then((res) => {
        setNoticeData(res);
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
        history.navigate('/notices', { replace: true});
      })

  }, []);
  
  return (
    <>
      {noticeData && <div className="container">
          <div className="notice_view_top">
              <h2>{noticeData.title}</h2>
              <small className="coldg"><Moment format='YYYY.MM.DD'>{noticeData.created}</Moment></small>
          </div>
          <div className="container_inner">
            <div className="notice_view_text" dangerouslySetInnerHTML={{__html: noticeData.body}} />
          </div>
      </div>}
    </>
  );
}

export default NoticeDetail;
