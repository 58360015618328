import api from '@services/api';
import queryString from 'query-string';

class AccountService {
  login(user_id, password) {
    return api.post('/accounts/authenticate', { user_id, password })
  }

  loginWithNaver(code) {
    return api.post('/accounts/authenticate-naver', { code })
  }

  loginWithKakao(code) {
    return api.post('/accounts/authenticate-kakao', { code })
  }

  forgotPassword(params) {
    return api.post('/accounts/forgot-password', params)
  }

  signup(params) {
    return api.post('/accounts', params)
  }

  createAccount(params) {
    return api.post('/accounts/register', params)
  }

  updateAccount(user_id, params) {
    return api.put(`/accounts/${user_id}`, params)
  }

  updateAvatar(user_id, params) {
    return api.put(`/accounts/${user_id}/avatar`, params)
  }

  deleteAccount(user_id, params) {
    return api.put(`/accounts/${user_id}/leave`, params)
  }

  refreshToken(){
    return api.post('/accounts/refresh-token')
  }

  user(user_id) {
    return api .get(`/accounts/${user_id}`)
  }

  rank() {
    return api.get(`/accounts/rank`)
  }

  users(params) {
    return api.get(`/accounts?${queryString.stringify(params)}`)
  }

  existsUserId(userId) {
    return api.get(`/accounts/${userId}/exists`)
  }
}

export default new AccountService();
