import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Footer from '@components/Footer';

import React, { Component } from "react";
import Slider from "react-slick";
import SoloMission from '@screens/Mission/Components/ListItem/SoloMission'
import TeamMission from '@screens/Mission/Components/ListItem/TeamMission'
import StampMission from '@screens/Mission/Components/ListItem/StampMission'
import SpotItem from '@screens/Spot/Components/ListItem/Spot'

import { missions } from '@actions/mission';
import { spots } from '@actions/spot';
import _ from "lodash";
import { user } from '@actions/account';

import PulseLoader from "react-spinners/BeatLoader";
import LottieAnimation from '@components/LottieAnimation';
import useDynamicRefs from 'use-dynamic-refs';

export default function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getRef, setRef] =  useDynamicRefs();
  const params = useParams();

  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const currentUser = useSelector(state => state.user);
  
  useEffect(() => {
    dispatch(user(currentUser.user.id))
  }, [dispatch]);

  useEffect(() => {
    if(!['solo','team','spot','stamp'].includes(params.mission_type)){
      navigate('/home/solo', {replace: true})
    }
  }, [])

  useEffect(() => {
    setCurrentItems(null);
    dispatch(params.mission_type === 'spot' ? spots({
      page: currentPage,
      limit: pageLimit,
    }) : missions({
      type: params.mission_type,
      page: currentPage,
      limit: pageLimit,
    }))
    .then((res) => {
      setTotalPageCount(1);
      setTotalItemCount(res.data.length);
      setCurrentItems(res.data);
    })
  }, [params.mission_type]);

  return (
    <>
      {<><div className="container">
        <div className="container_inner">
          <div className="index_welcome_note">
            <div className="index_welcome_note__left">
              <h2>
                <span className="colblue">{currentUser.user.nickname}</span>님,<br/>다양한 미션에 참여해<br/>상품을 받아보세요!
              </h2>
              <Link to="/my-missions"><small>나의 미션 보기 </small><img src={require('@assets/img/arrow_right_gray_thin.png')}/></Link>
            </div>
            <div className="index_welcome_note__right">
              <img src={require('@assets/img/img_rewards2.png')}/>
            </div>
          </div>
          <div className="index_tip_slider_wrap">
            <Slider className="index_tip_slider" {...{
                // autoplay:true,
                arrows:false,
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function(i) {
                  return (
                    <span>{i+1 + " / " + 2}</span>
                  )
                }
              }} ref={setRef('slider')}>
              <div>
                <Link to="#">
                  <img src={require('@assets/img/img_index_banner.png')} alt=""/>
                </Link>
              </div>
              <div>
                <Link to="#">
                  <img src={require('@assets/img/img_index_banner.png')} alt=""/>
                </Link>
              </div>
            </Slider>
          </div>
          <div className="quarter_wrap index_quarter_wrap">
              <label htmlFor="index_solo" className={`index_solo ${params.mission_type === 'solo' && 'active'}`}>
                <Link to={'/home/solo'}>
                  <strong>1인 미션 투어</strong>
                </Link>
              </label>
              <label htmlFor="index_team" className={`index_team ${params.mission_type === 'team' && 'active'}`}>
                <Link to={'/home/team'}>
                  <strong>단체 미션 투어</strong>
                </Link>
              </label>
              <label htmlFor="index_stamp" className={`index_stamp ${params.mission_type === 'stamp' && 'active'}`}>
                <Link to={'/home/stamp'}>
                  <strong>스탬프 투어</strong>
                </Link>
              </label>
              <label htmlFor="index_spots" className={`index_spots ${params.mission_type === 'spot' && 'active'}`}>
                <Link to={'/home/spot'}>
                  <strong>광주 관광지</strong>
                </Link>
              </label>
          </div>
          <ul className="index_quarter_contents mb40">
          {
            //loading data 
            !currentItems ? 
              <li style={{textAlign:'center'}}>
                <PulseLoader
                  color={'#00BF8E'}
                  loading={true}
                  size={15}
                />
              </li> 
              : 
              currentItems.length === 0 ? <>
                {console.log('currentItems',currentItems)}
                {params.mission_type === 'solo' && <li style={{textAlign:'center'}}>
                  <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                  참여 가능한 1인 미션투어가 없습니다.
                </li>}
                {params.mission_type === 'team' && <li style={{textAlign:'center'}}>
                  <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                  참여 가능한 단체 미션투어가 없습니다.
                </li>}
                {params.mission_type === 'stamp' && <li style={{textAlign:'center'}}>
                  <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                  참여 가능한 스템프투어가 없습니다.
                </li>}
                {params.mission_type === 'spot' && <li style={{textAlign:'center'}}>
                  <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                  관광지가 없습니다.
                </li>}
              </>
            : 
            currentItems.map(item=>
              <li key={item.id}>
                <div className="index_mission_wrap">
                  <div className="mission_items">
                    {
                      item.type === 'solo' && <SoloMission data={item}/>
                    }
                    {
                      item.type === 'team' && <TeamMission data={item}/>
                    }
                    {
                      item.type === 'stamp' && <StampMission data={item}/>
                    }
                    {
                      !['solo','team','stamp'].includes(item.type) && <SpotItem data={item}/>
                    }
                    {/* <div className="load_more_wrap">
                      <button className="toggle_load">더보기</button>
                    </div> */}
                  </div>
                </div>
              </li>
            // {}
            )}
          </ul>
        </div>
      </div>
      <Footer /> 
      </>}
    </>
  );
}
