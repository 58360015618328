import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '@helpers/history';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { missions } from '@actions/mission';
import { setIsLoading } from '@actions/common';
import SoloMission from '@screens/Mission/Components/ListItem/SoloMission'
import TeamMission from '@screens/Mission/Components/ListItem/TeamMission'
import StampMission from '@screens/Mission/Components/ListItem/StampMission'
import LottieAnimation from '@components/LottieAnimation';


const MissionList = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [isWinnerPop, setIsWinnerPop] = useState(false);
  
  const [currentItems, setCurrentItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(missions({
      type: params.type
    }))
    .then((res) => {
      setTotalPageCount(1);
      setTotalItemCount(res.data.length);
      setCurrentItems(res.data);
      dispatch(setIsLoading(false));
    })
  }, []);


  return (
    <>
      <div className="container">
        <div className="container_inner">
          {/* <div className="index_search_wrap mb30">
            <input type="text" placeholder="찾으시는 미션 or 지역을 검색해 주세요" />
            <button className="btn_search_gray"><span className="hide">검색</span></button>
          </div> */}
          <div className="index_mission_wrap">
            <div className="mission_items">
              <button className="btn_mission_back" onClick={()=>{history.navigate(`/home/${params.type}`); }}></button>

              {
                currentItems && currentItems.length === 0 &&<div className='no_data'>
                <LottieAnimation width="250px" data={require('@/assets/lottie/13525-empty.json')}/>
                <span>관광지 정보가 없습니다.</span>
              </div>
              }
              {currentItems && currentItems.map((item, index) => 
                item.type === 'solo' ? <SoloMission data={item}/> :
                item.type === 'team' ? <TeamMission data={item}/> :
                item.type === 'stamp' ? <StampMission data={item}/> : null
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="mission_winners" className="modal fade in" style={{display: isWinnerPop ? 'block' : 'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
                <ul className="pop_winners_wrap">
                  <li>
                      <strong className="rank_list__no">1</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>고수</strong>
                        <span className="coldg">참여9 / 완료 7 / 승률 77.77%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">2</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">3</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">4</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">5</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">6</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">7</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">8</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">9</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_default" onClick={()=>setIsWinnerPop(false)}>확인</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MissionList;
