import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Slider from "react-slick";

import { spot, like } from '@actions/spot';
import { setIsLoading } from '@actions/common';

import config from '@/config';
import ShareLink from 'react-facebook-share-link'

import {Map, MapMarker} from 'react-kakao-maps-sdk';

import * as helper from '@/helper'

const SpotDetail = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [spotData, setSpotData] = useState(null);
  const [isSpotShare, setIsSpotShare] = useState(false);
  const [additionalImages, setAdditionalImages] = useState([]);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(spot(params.spot_id))
      .then((res) => {
        setSpotData(res);
        const additional_images = Array.from({length: 10}, (v, i) => {
          return res[`additional_image${i+1}`]
        })

        setAdditionalImages(additional_images.filter(item => item !== null && item !== undefined))

        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
      })

  }, []);

  useEffect(() => {
    //카카오톡 sdk추가
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [])


  const onShareToKakaoTalk = () => {
    if(window.Kakao) {  
      const kakao = window.Kakao;

      if (!kakao.isInitialized()) {
        kakao.init(config.kakaoAppKey);
      }
      // <img src={`${config.apiServerHost}/missions/attachment/${data?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/480x200'}/>

      kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: spotData.title,
          description: helper.extractContent(spotData.description),
          imageUrl: `${config.apiServerHost}/spots/attachment/${spotData?.intro_image?.server_filename}`,
          link: {
            mobileWebUrl: `${config.baseUrl}/spots/${params.spot_id}`,
            webUrl: `${config.baseUrl}/spots/${params.spot_id}`,
          },
        },
        buttons: [
          {
            title: '관광지 보러가기',
            link: {
              mobileWebUrl: `${config.baseUrl}/spots/${params.spot_id}`,
              webUrl: `${config.baseUrl}/spots/${params.spot_id}`,
            },
          },
        ],
      })

      setIsSpotShare(false);
    }
  }

  const onLike = () => {
    dispatch(like(spotData._id))
    .then(res => {
      setSpotData({...spotData, is_liked: res.result});
    })
  }

  const onCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    toast.success('링크가 복사되었습니다.');
    setIsSpotShare(false);
  }

  return (
    <>
      {spotData && <div className="container">
        <div className="container_inner container_fit">
            <p className="detail_cate" data-name="spots"><img src={require('@assets/img/icon_h4_yellow.png')} /><strong>광주 관광지</strong></p>
            <h2 className="detail_title">{spotData.title}</h2>
            <dl className="detail_spot_info">
              <dt>위치</dt>
              <dd>
                {spotData.address}
                {/* {spotData.coordinate.lat && spotData.coordinate.lng && <Map
                    center={{
                      lat: spotData.coordinate.lat,
                      lng: spotData.coordinate.lng,
                    }}
                    style={{
                      width: "100%",
                      height: "200px",
                      marginBottom: '10px'
                    }}
                    level={3}
                  >
                  <MapMarker
                    position={{
                      lat: spotData.coordinate?.lat,
                      lng: spotData.coordinate?.lng,
                    }}
                  />
                  </Map>} */}
              </dd>
              <dt>목적</dt>
              <dd className="mb10">
                <span>{spotData.type == 'tour' ? '관광' : '체험'}</span>
                <div className="mission_info_buttons">
                  <button type="button" className="btn_share mr15" onClick={()=>setIsSpotShare(true)}></button>
                  <button type="button" className={`btn_like ${spotData.is_liked ? "btn_liked" : ""}`} onClick={()=>onLike()}></button>
                </div>
              </dd>
            </dl>
            {additionalImages.length > 0 && <div className="detail_img_slider_wrap">
                <Slider className='detail_img_slider'  {...{
                autoplay:true,
                arrows:false,
                dots:true,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function(i) {
                  return (
                    <span>{i+1 + " / " + additionalImages.length}</span>
                  )
                }
              }}>
              {additionalImages.map(image=><div>
                <img src={`${config.apiServerHost}/spots/attachment/${image?.server_filename}`} alt="여행이미지" onError={e=>e.target.src = 'https://via.placeholder.com/282'}/>
              </div>)}

                </Slider>
                {/* <div className="detail_img_slider">
                </div> */}
            </div>}
            <p className="colblue mb15"><strong><small>상세 설명</small></strong></p>
            <p className="mb20" dangerouslySetInnerHTML={{__html: spotData.description}} />
            <figure class="audio_wrap">
            { spotData?.audio?.server_filename && <audio
                controls
                src={`${config.apiServerHost}/spots/attachment/${spotData?.audio?.server_filename}`}>
            </audio> }
            </figure>
          </div>
        </div>
      }
      <div id="detail_share" className="modal fade" style={{display: isSpotShare? 'block':'none'}}>
          <div className='modal-backdrop in'></div>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body">
                  <div>
                    <div className="detail_share_header">
                      <strong>공유하기</strong>
                      <button className="btn_modal_close" onClick={()=>setIsSpotShare(false)}><span className="hide">모달 닫기</span></button>
                    </div>
                    <div className="detail_share_links">
                      <a href="#" onClick={()=>onCopyUrl()}>
                      <img src={require('@assets/img/img_copy.png')} alt="링크 복사" />
                      <small>링크 복사</small>
                    </a>
                    <a href="#" onClick={()=>onShareToKakaoTalk()}>
                      <img src={require('@assets/img/img_kakao.png')} alt="카카오톡으로 공유" />
                      <small>카카오톡</small>
                    </a>
                    <ShareLink link={window.location.href}>
                      {link => (
                          <a href={link} target='_blank' onClick={()=>setIsSpotShare(false)}>
                          <img src={require('@assets/img/img_fb.png')} alt="페이스북으로 공유" />
                          <small>페이스북</small>
                        </a>
                      )}
                    </ShareLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default SpotDetail;
