import { useEffect } from 'react';
import lottie from 'lottie-web';

function LottieAnimation({data, width, id, display}) {

  useEffect(()=>{
    const container = document.querySelector(id ? `#${id}` : '#lottie_container');

    lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: data
    })
  }, [data])

  return (
    <>
      <div id={id || "lottie_container"} style={{display: display || 'block', width: width || '200px'}}></div>
    </>
  );
}

export default LottieAnimation;
