import { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

const RegisterTerm = () => {
  
  const [checkList, setCheckList] = useState([]);
  const [idList] = useState(['term1','term2','term3','term4','term5']);
  const [isTermPop, setIsTermPop] = useState(false);

  const onChangeAll = (e) => {
    setCheckList(e.target.checked ? idList : [])
  }

  const onChangeEach = (e) => {
    if (e.target.checked) {
      setCheckList([...checkList, e.target.value]);
    } else {
      setCheckList(checkList.filter((checkedId) => checkedId !== e.target.value));
    }
  }
  return (
    <>
      <div className="login_back_wrap">
        <Link to="/login" className="btn_back"><span className="hide">뒤로 가기</span></Link>
      </div>
      <div className="container" style={{paddingTop:0}}>
        <div className="container_inner">
          <h2 className="mb40">회원가입 약관</h2>
          <form id="register_email">
            <div className="term_chkall_wrap">
              <input type="checkbox" name="term_chkall" id="term_chkall" className="chk_default mr15" onChange={onChangeAll} checked={checkList.length === idList.length} />
              <label htmlFor="term_chkall">약관 전체 동의</label>
            </div>
            <div className="term_chk_wrap">
              <input type="checkbox" name="term_chk[0]" id="term_chk[0]" className="chk_default mr15" value={'term1'} onChange={(e) => onChangeEach(e)} checked={checkList.includes('term1')}/>
              <label htmlFor="term_chk[0]">만 14세 이상입니다 (필수)</label>
              <button className="pop_term" type="button" onClick={()=>setIsTermPop(true)}><span className="hide">만 14세 이상입니다 약관 모달 열기</span></button>
            </div>
            <div className="term_chk_wrap">
              <input type="checkbox" name="term_chk[0]" id="term_chk[1]" className="chk_default mr15" value={'term2'} onChange={(e) => onChangeEach(e)} checked={checkList.includes('term2')}/>
              <label htmlFor="term_chk[1]">서비스 이용약관 동의 (필수)</label>
              <button className="pop_term" type="button"><span className="hide">서비스 이용약관 동의 약관 모달 열기</span></button>
            </div>
            <div className="term_chk_wrap">
              <input type="checkbox" name="term_chk[0]" id="term_chk[2]" className="chk_default mr15" value={'term3'} onChange={(e) => onChangeEach(e)} checked={checkList.includes('term3')}/>
              <label htmlFor="term_chk[2]">개인정보 처리방침 동의 (필수)</label>
              <button className="pop_term" type="button"><span className="hide">개인정보 처리방침 동의 약관 모달 열기</span></button>
            </div>
            <div className="term_chk_wrap">
              <input type="checkbox" name="term_chk[0]" id="term_chk[3]" className="chk_default mr15" value={'term4'} onChange={(e) => onChangeEach(e)} checked={checkList.includes('term4')}/>
              <label htmlFor="term_chk[3]">개인정보 수짐 및 이용 동의 (필수)</label>
              <button className="pop_term" type="button"><span className="hide">개인정보 수짐 및 이용 동의 약관 모달 열기</span></button>
            </div>
            <div className="term_chk_wrap">
              <input type="checkbox" name="term_chk[0]" id="term_chk[4]" className="chk_default mr15" value={'term5'} onChange={(e) => onChangeEach(e)} checked={checkList.includes('term5')}/>
              <label htmlFor="term_chk[4]">개인정보 제3자 제공 동의 (필수)</label>
              <button className="pop_term" type="button"><span className="hide">개인정보 제3자 제공 동의 약관 모달 열기</span></button>
            </div>
          </form>
          <div className="register_bottom">
            <small>계정 생성 시 이용약관, 개인정보처리방침<br/>및 위치기반 서비스 이용약관에 동의하게 됩니다.</small>
              {checkList.length === idList.length ? <Link to="/register/form">
                <button className="btn_lg">동의 후 인증하기</button>
              </Link> : <button className="btn_lg_gray">동의 후 인증하기</button>}
          </div>
        </div>
      </div>
      
      <div id="term_age" className="modal fade" style={{display: isTermPop? 'block':'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-body modal-term">
                  <p className="mb15"><strong>만 14세 이상 입니다(필수)</strong></p>
                  <small>본 약관은 “(주)칼라애드”회사(이하 “회사”라 함)가 운영하는 인터넷 홈페이지(https://dev.martshop.co.kr) 또는 “모바일 애플리케이션에서 제공하는 MartShop서비스(이하 “서비스”라 함)를 이용함에 있어 “회사”와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다본 약관은 “(주)칼라애드”회사(이하 “회사”라 함)가 운영하는 인터넷 홈페이지(https://dev.martshop.co.kr) 또는 “모바일 애플리케이션에서 제공하는 MartShop서비스(이하 “서비스”라 함)를 이용함에 있어 “회사”와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다본 약관은 “(주)칼라애드”회사(이하 “회사”라 함)가 운영하는 인터넷 홈페이지(https://dev.martshop.co.kr) 또는 “모바일 애플리케이션에서 제공하는 MartShop서비스(이하 “서비스”라 함)를 이용함에 있어 “회사”와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다본 약관은 “(주)칼라애드”회사(이하 “회사”라 함)가 운영하는 인터넷 홈페이지(https://dev.martshop.co.kr) 또는 “모바일 애플리케이션에서 제공하는 MartShop서비스(이하 “서비스”라 함)를 이용함에 있어 “회사”와 이용자의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다</small>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn_default" onClick={()=>setIsTermPop(false)}>확인</button>
                </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default RegisterTerm;
