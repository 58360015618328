import _ from 'lodash';

const StampDetail = ({data}) => {
  return (
    <>
      <div>
        <span className="divide_thick mb20"></span>
        <div className="container_inner container_fit">
          <p className="colblue mb15"><strong><small>미션 내용</small></strong></p>
          <div className="mission_contents">
            {/* 스탬프투어일 경우 노출 & 미션 개수에 따라 stamp_wrap 내의 클래스가 다르게 적용되어야함 
                4분할=mission_stamp_quarter, 9분할=mission_stamp_nine 
                4분할인 경우에는 mission_stamp_wrap의 style grid-template-columns: repeat(2, 1fr)로 적용
                9분할인 경우에는 mission_stamp_wrap의 style grid-template-columns: repeat(3, 1fr)로 적용
                */}
            <div className={`mission_stamp_wrap ${data.stamp_image_divide_num === 4 ? 'grid-col-2':'grid-col-3'}`}>
              {
                _.filter(data.quiz, r=>r.title).map(item=><div className={`${data.stamp_image_divide_num === 4 ? 'mission_stamp_quarter':'mission_stamp_nine'} bg${data.stamp_image_num}`} key={item._id}><strong>{item.location.name}</strong></div>)
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StampDetail;
