import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import history from '@helpers/history';
import _ from 'lodash';

import Moment from 'react-moment';
import moment from 'moment'
import config from '@/config';
import { rank } from '@actions/account';
import { setIsLoading } from '@actions/common';
import * as helper from '@/helper'

const RankUser = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [rankData, setRankData] = useState(null);
  const [myRankData, setMyRankData] = useState(null);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(rank())
      .then((res) => {
        const myRankData = _.find(res, r=>r._id === params.rank_user_id)
        if(!myRankData || (myRankData.success_mission_count < 1 && myRankData.fail_mission_count < 1)) {
          history.navigate('/rank', {replace: true});
        }
        setRankData(res);
        setMyRankData(myRankData);
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
      })
  }, []);

  return (
    <>
      {myRankData && <div className="container">
        <div className="container_inner container_fit">
          <h2>유저정보</h2>
          <div className="rank_mine_wrap rank_user_wrap">
            <div className="rank_mine">
              <div className="rank_mine__img">
               {myRankData?.avatar?.server_filename ? <img src={`${config.apiServerHost}/accounts/avatar/${myRankData?.avatar?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/480x200'}/> : <img src={require('@assets/img/avatar.png')} alt="" width={'100%'} />}
              </div>
              <div className="rank_mine__text">
                <p>
                  <h3>{_.findIndex(rankData, r=>r._id === params.rank_user_id) + 1}</h3>
                  <h4> 위</h4>
                  <strong>{myRankData.nickname}</strong>
                </p>
                <p>
                  <span className="">참여 {myRankData.success_mission_count+myRankData.fail_mission_count} / 성공 {myRankData.success_mission_count} / 승률 { Math.round((myRankData.success_mission_count / (myRankData.success_mission_count+myRankData.fail_mission_count)) * 100) }%</span>
                </p>
              </div>
            </div>
          </div>
          <div className="mission_items rank_mission_items">
            {myRankData.join_mission.map((item, index) => <div className="mission_item">
              <div className="mission_period_wrap">
                {item.mission.type === 'solo' && <strong className="colgreen">1인 미션 투어</strong>}
                {item.mission.type === 'team' && <strong className="colblue">단체 미션 투어</strong>}
                {item.mission.type === 'stamp' && <strong className="colred">스탬프 투어</strong>}
                <small><Moment format='YYYY.MM.DD'>{item.play_date}</Moment></small>
              </div>
              <h4 className="mission_title">{item.mission.title}</h4>
              <div className="mission_info">
                <small>
                  <img src={require('@assets/img/icon_time_gray.png')} />
                  <strong>
                    {helper.secondsToTime(moment(item.close_date).diff(moment(item.play_date), 'seconds'))}
                  </strong>
                </small>
                <span className={`mission_info_result ${item.result?'':'mission_info_result__fail'}`}>
                  미션 {item.result?'성공':'실패'}
                </span>
              </div>
            </div>)}
          </div>
        </div>
      </div>}
    </>
  );
}

export default RankUser;
