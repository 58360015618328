import history from '@helpers/history';

import {
  UPDATE_USER_INFO,
  SET_ACCESS_TOKEN,
  CLEAR_ACCESS_TOKEN,
  SET_CURRENT_USER,
} from './types'

import AccountService from '@services/account.service';

const JWT_EXPIRY_TIME = 30 * 60 * 1000; // 만료 시간

export const signup = (params) => (dispatch) => {
  return AccountService.signup(params)
    .then((response)=>{
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);
      
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

        dispatch({
          type: SET_CURRENT_USER,
          payload: {}
        })

        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: ''
        })

      return Promise.reject(message);
    }
  );
};

export const login = (user_id, password) => (dispatch) => {
  return AccountService.login(user_id, password)
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      dispatch({
        type: SET_CURRENT_USER,
        payload: {}
      })
      
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: ''
      })

      return Promise.reject(message);
    }
  );
};

//login with naver
export const loginWithNaver = (token) => (dispatch) => {
  return AccountService.loginWithNaver(token)
  .then(response => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: response.data.user,
    });

    dispatch(setAccessToken(response.data.accessToken));

    setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);

    return Promise.resolve(response.data);
  },
  (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_CURRENT_USER,
        payload: {}
      })
      
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: ''
      })

      return Promise.reject(message);
    }
  );
};

//login with kakao
export const loginWithKakao = (code) => (dispatch) => {
  return AccountService.loginWithKakao(code)
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_CURRENT_USER,
        payload: {}
      })
      
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: ''
      })

      return Promise.reject(message);
    }
  );
};


export const createAccount = (params) => (dispatch) => {
  return AccountService.createAccount(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};

export const deleteAccount = (user_id, params) => (dispatch) => {
  return AccountService.deleteAccount(user_id, params)
  .then((response)=>{
    return Promise.resolve(response.data);
  },
  (error) => {
    const message =
      (error.response
        && error.response.data
        && error.response.data.message) || error.message || error.toString();

    return Promise.reject(message);
  }
);
};


export const forgotPassword = (userId, emailDomain) => (dispatch) => {
  return AccountService.forgotPassword({userId, emailDomain})
    .then(response => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
      (error.response &&
       error.response.data &&
       error.response.data.message) ||
       error.message ||
       error.toString();

      return Promise.reject(message);
    }
  );
}

export const refreshToken = () => (dispatch, selector) => {
  if(selector((state) => state)?.auth?.accessToken) {
    return AccountService.refreshToken()
    .then(response => {
      dispatch({
        type: SET_CURRENT_USER,
        payload: response.data.user,
      });

      dispatch(setAccessToken(response.data.accessToken));

      setTimeout(()=>dispatch(refreshToken()), JWT_EXPIRY_TIME - 60000);
    },
    (error) => {
      if(error.response.status === 400 && error.response.data.message === 'Invalid token'){
        dispatch(logout()).then(() => history.navigate('/',{replace: true}));
      }
    }
  );    
  }
}

export const user = (user_id) => (dispatch, selector) => {
  return AccountService.user(user_id)
    .then(response => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const me = () => (dispatch, selector) => {
  return AccountService.me()
    .then(response => {

      dispatch({
        type: UPDATE_USER_INFO,
        payload: {       
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          role: response.data.role,
          permissions: response.data.permissions,
          isVerified: response.data.isVerified
        },
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const updateAccount = (userId, params) => (dispatch) => {
  return AccountService.updateAccount(userId, params)
    .then(response => {
      dispatch({
        type: UPDATE_USER_INFO,
        payload: {       
          firstName: response.data.firstName,
          lastName: response.data.lastName
        },
      });

      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const updateAvatar = (user_id, params) => (dispatch) => {
  return AccountService.updateAvatar(user_id, params)
    .then(response => {
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
}

export const users = (params) => (dispatch) => {
  return AccountService.users(params)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const existsUserId = (userId) => (dispatch) => {
  return AccountService.existsUserId(userId)
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      return Promise.reject(message);
    }
  );
};

export const logout = () => (dispatch) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "token/save", data: null })
    );
  }

  dispatch(setCurrentUser({}));
  dispatch(setAccessToken(''));

  return Promise.resolve({message: 'logout successfully'});
}

export const setCurrentUser = (currentUser = {}) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: currentUser
  })
}

export const setAccessToken = (accessToken) => (dispatch) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: accessToken
    })
  } else {
    localStorage.removeItem('accessToken')
    dispatch({
      type: CLEAR_ACCESS_TOKEN
    })
  }
}

export const rank = () => (dispatch) => {
  return AccountService.rank()
    .then((response)=>{
      return Promise.resolve(response.data);
    },
    (error) => {
      const message =
        (error.response
          && error.response.data
          && error.response.data.message) || error.message || error.toString();

      return Promise.reject(message);
    }
  );
};