import { useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom'; 
import config from '@config';

import { like } from '@actions/spot';

const SpotItem = ({data}) => {
  const dispatch = useDispatch();
  
  const [itemData, setItemData] = useState(data);
  
  const onLike = () => {
    dispatch(like(itemData._id))
    .then(res => {
      setItemData({...itemData, is_liked: res.result})
    })
  }

  return (
    <div className="mission_item">
      <Link to={`/spots/${itemData._id}`} className="img_wrap">
        {itemData?.intro_image?.server_filename ? <img src={`${config.apiServerHost}/spots/attachment/${itemData?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/480x200'}/> : <img src='https://via.placeholder.com/480x200' alt="" width={'100%'}/>}
      </Link>
      <div className="mission_period_wrap">
        <small>
          <strong className="colblue">{itemData.type == 'tour' ? '관광' : '체험'}</strong>
        </small>
        <button type="button" className={`btn_like ${itemData.is_liked ? "btn_liked" : ""}`} onClick={()=>onLike()}></button>
      </div>
      <h4 className="spot_title"><Link to={`/spots/${itemData._id}`}>{itemData.title}</Link></h4>
    </div>
  );
}

export default SpotItem;
