import api from '@/services/api';
import queryString from 'query-string';

class SpotService {
  spot(spot_id) {
    return api.get(`/spots/${spot_id}`)
  }

  spots(params) {
    return api.get(`/spots?${queryString.stringify(params)}`)
  }

  like(spot_id) {
    return api.put(`/spots/${spot_id}/like`)
  }
}

export default new SpotService();
