import api from '@services/api';

class AuthService {
  login(userId, password) {
    return api
      .post('/accounts/authenticate', {
        userId,
        password
      })
  }

  forgotPassword(params) {
    return api.post('/accounts/forgot-password', params)
  }

  register(params) {
    return api.post('/accounts/register', params)
  }

  updateAccount(userId, params) {
    return api.put(`/accounts/${userId}`, params)
  }

  refreshToken(){
    return api.post('/accounts/refresh-token')
  }

  user(user_id) {
    return api.get(`/accounts/${user_id}`)
  }

  existsUserId(userId) {
    return api.get(`/accounts/${userId}/exists`)
  }
}

export default new AuthService();
