import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom'; 

import Moment from 'react-moment';
import config from '@/config';

import { mission, like } from '@actions/mission';

const StampMission = ({data}) => {
  const dispatch = useDispatch();
  const [isWinnerPop, setIsWinnerPop] = useState(false);
  const [itemData, setItemData] = useState(data);
  
  const onLike = () => {
    dispatch(like(itemData._id))
    .then(res => {
      setItemData({...itemData, is_liked: res.result})
    })
  }
  
  return (
    <>
      <div className="mission_item">
        <Link to={`/missions/${itemData._id}/view`} className="img_wrap">
          <img src={`${config.apiServerHost}/missions/attachment/${itemData?.intro_image?.server_filename}`} alt="" width={'100%'} onError={e=>{e.target.onerror = null; e.target.src = 'https://via.placeholder.com/480x200'}}/>
        </Link>
        <div className="mission_period_wrap">
          <small>
            <strong className="colblue">미션 기간</strong>
            {itemData.is_unlimit_date ? '상시' : <><Moment format='YYYY.MM.DD HH:mm'>{itemData.from_date}</Moment> ~ <Moment format='YYYY.MM.DD HH:mm'>{itemData.to_date}</Moment></>}
          </small>
          <button type="button" className={`btn_like ${itemData.is_liked ? "btn_liked" : ""}`} onClick={()=>onLike()}></button>
        </div>
        <h4 className="mission_title"><Link to={`/missions/${itemData.id}/view`}>{itemData.title}</Link></h4>
        <div className="mission_info">
          <small>
            <img src={require('@assets/img/icon_time_gray.png')} style={{marginTop:'-2px'}}/><strong>{itemData.limit_time}시간</strong>
          </small>
          {/* 미션 기간 도래 여부에 따라 미션 참여 가격(mission_price), 수상자 보기(mission_info_winner)로 분류하여 각각 다른 클래스를 표시 */}
          {/* <div className="mission_info_winner">
            <a onClick={()=>setIsWinnerPop(true)}>수상자 보기</a>
          </div> */}
        </div>
      </div>
      <div id="mission_winners" className="modal fade in" style={{display: isWinnerPop ? 'block' : 'none'}}>
        <div className='modal-backdrop in'></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
                <ul className="pop_winners_wrap">
                  <li>
                      <strong className="rank_list__no">1</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>고수</strong>
                        <span className="coldg">참여9 / 완료 7 / 승률 77.77%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">2</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">3</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">4</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">5</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">6</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1504257432389-52343af06ae3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>침착맨</strong>
                        <span className="coldg">참여8 / 완료 6 / 승률 75%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">7</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">8</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                  <li>
                      <strong className="rank_list__no">9</strong>
                      <div className="rank_list__img">
                        <img src="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" />
                      </div>
                      <div className="rank_list__text">
                        <strong>내가왕이다</strong>
                        <span className="coldg">참여6 / 완료 4 / 승률 66.66%</span>
                      </div>
                  </li>
                </ul>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn_default" onClick={()=>setIsWinnerPop(false)}>확인</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StampMission;
