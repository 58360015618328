import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import config from '@/config';

import { rank } from '@actions/account';
import { setIsLoading } from '@actions/common';

const RankList = () => {
  const dispatch = useDispatch();

  const [isProfileToggle, setIsProfileToggle] = useState(false);
  const [rankData, setRankData] = useState(null);
  const [myRankData, setMyRankData] = useState(null);
  const [filteredRankData, setFilteredRankData] = useState(null);
  const currentUser = useSelector(state => state.user);

  useEffect(() => {
    dispatch(setIsLoading(true));
    dispatch(rank())
      .then((res) => {
        setRankData(res);
        setFilteredRankData(res);
        setMyRankData(_.find(res, r=>r._id === currentUser.user.id));
        dispatch(setIsLoading(false));
      })
      .catch((err) => {
        toast.error(err.message);
        dispatch(setIsLoading(false));
      })

  }, []);

  return (
    <>
        <div className="container">
          {/* <div className="rank_search_wrap">
            <input type="text" placeholder="찾으시는 닉네임을 검색해 주세요" />
            <button className="btn_clear"><span className="hide">내용 지우기</span></button>
            <button className="btn_search"><span className="hide">검색</span></button>
          </div> */}
          {rankData && <><div className="rank_mine_wrap">
            {/* <button type="button" className={`toggle_sibling ${isProfileToggle? "" : "active"}`} onClick={()=>{toggleProfile()}}> */}
              <strong>나의 랭킹</strong>
            {/* </button> */}
            <div className="rank_mine" style={{display: isProfileToggle? 'none' : 'flex', marginTop: '10px'}}>
              <div className="rank_mine__img">
                {/* <img src={require('@assets/img/avatar.png')} alt="내 프로필 사진" /> */}
                {myRankData?.avatar?.server_filename ? <img src={`${config.apiServerHost}/accounts/avatar/${myRankData?.avatar?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/480x200'}/> : <img src={require('@assets/img/avatar.png')} alt="" width={'100%'} />}
              </div>
              <div className="rank_mine__text">
                <p>
                  <h3>{_.findIndex(rankData, r=>r._id === currentUser.user.id) + 1}</h3>
                  <h4> 위</h4>
                  <strong>{myRankData.nickname}</strong>
                </p>
                <p>
                  {myRankData.success_mission_count > 0 ? <span className="">참여 {myRankData.success_mission_count+myRankData.fail_mission_count} / 성공 {myRankData.success_mission_count} / 승률 { Math.round((myRankData.success_mission_count / (myRankData.success_mission_count+myRankData.fail_mission_count)) * 100) }%</span>
                  : <span className="">-</span>}
                </p>
              </div>
            </div>
          </div>
          {/* <span className="divide_thick mb20" style={{display: isProfileToggle? 'none' : 'flex'}}></span> */}
          </>
          }
          <div className="container_inner container_fit">
            {filteredRankData && <div className="rank_lists">
              {filteredRankData.map((item, index) => <Link to={item.success_mission_count > 0 || item.fail_mission_count > 0 ? `/rank/user/${item._id}` : '#'} className="rank_list">
                <strong className="rank_list__no">{index+1}</strong>
                <div className="rank_list__img">
                  {/* <img src="https://images.unsplash.com/photo-1531746020798-e6953c6e8e04?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1964&q=80" /> */}
                  {item?.avatar?.server_filename ? <img src={`${config.apiServerHost}/accounts/avatar/${item?.avatar?.server_filename}`} alt="" width={'100%'} onError={e=>e.target.src = 'https://via.placeholder.com/480x200'}/> : <img src={require('@assets/img/avatar.png')} alt="" width={'100%'} />}
                </div>
                <div className="rank_list__text">
                  <strong>{item.nickname}</strong>
                  {item.success_mission_count > 0 || item.fail_mission_count > 0 ? <span className="coldg">참여 {item.success_mission_count+item.fail_mission_count} / 성공 {item.success_mission_count} / 승률 { Math.round((item.success_mission_count / (item.success_mission_count+item.fail_mission_count)) * 100) }%</span>
                  : <span className="coldg">-</span>}
                </div>
              </Link>)}
            </div>}
          </div>
        </div>
    </>
  );
}

export default RankList;
