import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from '@helpers/history';

import Router from './routes'

import { refreshToken } from '@actions/account';
import { site } from '@actions/site';
import PulseLoader from "react-spinners/BeatLoader";
import LottieAnimation from '@components/LottieAnimation';

function App() {
  const dispatch = useDispatch();

  history.navigate = useNavigate();
  history.location = useLocation();

  const { isLoading } = useSelector((state) => state.common);
  const { isDeniedGeolocation } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch(refreshToken())
    dispatch(site())
    dispatch({type: 'SET_IS_DENIED_GEOLOCATION', payload: false})
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);
  
  return (
    <>
      {isLoading && <div className='backdrop'>
        <PulseLoader
          color={'#00BF8E'}
          loading={true}
          size={15}
        />
      </div>}
      {isDeniedGeolocation && <div className='backdrop'>
        <LottieAnimation width="200px" id='denied_anim' data={require('@/assets/lottie/134313-permission-location.json')}/>
        <div className='mb20'>미션 수행을 위해 위치 정보 사용을 허용해 주세요.</div>
        <button type="button" className="btn_pop_yes" onClick={()=>history.navigate(`/`, {replace: true})  }>홈으로 이동</button>
      </div>}
      <Router/>
    </>  
  );
}

export default App;
